<template>
  <div id="note" class="note">
    <center>
        <br/>
        <b>{{title}}</b> VisitId- {{visitId}}
        <br/>
       
         <textarea v-model="note"  rows="5" cols="25"  ref="noteRef"></textarea>
         <br/><br/>

  
        <button @click="addUpdateNote">Add Note</button>&nbsp;
        <button @click="hideNoteBox">Close</button><br/>

       
        </center>
         <span>{{serverMessage}}</span>
  </div>
</template>

<script>


export default {
  name: 'AddUpdateNoteBox',
  mounted(){

      this.$root.$refs.AddUpdateNoteBox = this;
      this.setFocus();
  },
  data: function() {
            return {
             
             serverMessage:'',
             messageColour:'black',
             visitId:0,
             note:'',
           
            
            };
        },
  props:{
      title:String,
      

  },
  methods:{
      initialize:function(){
            this.serverMessage='';
            this.visitId=0;
            this.note='';
        },
        
     
      setFocus:function(){
            //alert("Set Focus");
            this.$nextTick(() => this.$refs.noteRef.focus())
        },
        hideNoteBox:function(){
            //alert("In Hide note box");
            this.$root.$refs.AppointmentBook.showAddUpdateNoteBox = false;
           this.initialize();
        },
        getNote:function(visitId,note){
            this.visitId=visitId;
            this.note=note;
            //alert("In getNote function in popup, visitId:" + visitId);
              
            this.user = JSON.parse(localStorage.getItem('user'));
            let formData = new FormData();
            formData.append('visitId', visitId);
            var url=process.env.VUE_APP_ROOT_API+"/appointment/getNoteByVisitId";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                this.note= response.data;
                                
                              
                            })
            .catch(error => {
             this.serverMessage=error.response.data;
            });
        
        },
        addUpdateNote:function(){
           
            this.user = JSON.parse(localStorage.getItem('user'));
            let formData = new FormData();
            formData.append('visitId', this.visitId);
            formData.append('note', this.note);
            var url=process.env.VUE_APP_ROOT_API+"/appointment/updateNote";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                this.serverMessage= response.data;
                                
                              
                            })
            .catch(error => {
             this.serverMessage=error.response.data;
            });
        
        }
}
}
</script>

<style scoped>
#note{
    position:absolute;
  left:15%;top:10%;
  z-index:5000;
  width:300px;
  height:270px;
  border-style:solid;
  border-color:white;
  border-width:2px;
 //background-color:#262525;
 background-color:#FFFF99;
  opacity:.95;
  font-family:fantasy;
  color:black;

}
.noteBox{
text-align:left;

}
.successMessage{
    color:green;
}
.errorMessage{
    color:red;
}
</style>
