<template>
  <div class="location">
    
    Location:

    <select  @change="selectLocation($event)">
          <option value="na" >Select</option>
          <option value="Craigieburn" selected>Craigieburn</option>
          <option value="Greenvale">Greenvale</option>
          <option value="Tullamarine">Gladstone Park</option>
          

    </select>
     
  </div>
  
</template>

<script>

export default {
 
  created:function(){
  
  },
  mounted(){

      this.$root.$refs.Location = this;
   
  },
  components:{
    

  },
  props: {
    title: String
  },
 data: function() {
        return {
          name:'Craigieburn'
          
        };
   },
  methods:{
          selectLocation:function(e){  
            //alert("In Location component, location selected : " + e.target.value);
            this.$root.$refs.AppointmentBook.clinicLocation = e.target.value;
          },
                    
         
 

  }
}
</script>
