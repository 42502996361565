<template>
  <div>
<h1>Send SMS</h1>

   <b-form-datepicker id="datepicker-buttons" today-button close-button v-model="appDate" class="mb-2" @context="getAppointmentFunc()"></b-form-datepicker>
  <b-form-select v-model="location" class="mb-3" @change="getAppointmentFunc()">
      <b-form-select-option :value="null">Please select an option</b-form-select-option>
      <b-form-select-option value="Tullamarine">Gladstone Park</b-form-select-option>
      <b-form-select-option value="Craigieburn">Craigieburn</b-form-select-option>
      <b-form-select-option value="Greenvale">Greenvale</b-form-select-option>    
  </b-form-select>

   <b-button variant="outline-primary" @click="sendSMSReminders()">Send</b-button>
  <table>
    <tr><th>#</th><th>PatientId</th><th>Name</th><th>Mobile</th><th>Receive SMS</th><th>Change</th><th>Status</th></tr>
    <tr v-for="(appointment,index) in appointmentsByDate" v-bind:key="index">
        <td>{{index}}</td>
        <td>{{appointment.id}}</td><td>{{appointment.patientFirstName}} {{appointment.patientLastName}}</td>
        <td>{{appointment.patientMobileNumber}}</td>
        <td>{{appointment.receiveAppointmentReminderSMS}}</td>
        <td>
        
        <input type="checkbox" :checked="appointment.receiveAppointmentReminderSMS=='yes'" @click="setSendSMS($event,index)"  />
        
        </td>
        <td>{{appointment.smsStatus}}</td>
    </tr>
  </table>
 
  
   </div>
 
</template>

<script>
export default {


  name: 'AppointmentReminder',
  

  
    props: 
        ['testProp','template','title'],
        
  
    data: function() {
        return {
         appointmentsByDate:[],
         appDate:'',
         location:'',
         user:''
        }
    },
    methods:{
            setSendSMS:function(e,index){

              if(e.target.checked){
                 this.appointmentsByDate[index].receiveAppointmentReminderSMS = "yes";   
                //alert("Checked:" + index +",Name:" + this.appointmentsByDate[index].id);
                            
                }
              if(!e.target.checked)
              {
                
                this.appointmentsByDate[index].receiveAppointmentReminderSMS="no";
                //alert("UnChecked:" + index +"Name:" + this.appointmentsByDate[index].patientFirstName);
                
              }
                

            },
            sendSMSReminders:function(){

                 // alert(this.appointmentsByDate);
                  var url=process.env.VUE_APP_ROOT_API+"/correspondence/sendSMSReminders";
                        this.$http.post(url,this.appointmentsByDate,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(
                (response) => {
                        
                          this.appointmentsByDate = response.data;
                         alert(response.data )
                          });

            },
           getAppointmentFunc:function(){

               //alert(this.appDate);
                this.user = JSON.parse(localStorage.getItem('user'));
                var url=process.env.VUE_APP_ROOT_API+"/appointment/getAppointmentList";
                let formData = new FormData();

                formData.append('date', this.appDate); 
                 formData.append('location', this.location); 
                this.$http.post(url, formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(
                (response) => {
                        
                          this.appointmentsByDate = response.data;
                         // alert(response.formData )
                          });
                          
                         
          }

            }
    
        
    }  


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
