<template>

  <div class="viewPaymentBox">
  
        <h3>{{title}} - VisitId :{{visitId}}</h3>
        <center>
          <table cellpadding="5">
            <tr><th>PmtId</th><th>Code</th><th>Cost</th><th>Payment</th><th>Product Purchased</th></tr>
            <tr>
                <td>{{pObj.paymentId}}</td>
                <td>
                    {{pObj.code}} <br>
                    {{pObj.codeDescription}}
                </td>

                <td>
                    ${{pObj.codeAmount}}
                </td>
                <td>
                    Gap:${{pObj.gapPaymentAmount}}<br>
                    Insurance:${{pObj.amountCoveredByHealthInsurance}} ({{pObj.healthInsuranceProvider}})<br>
                    EFTPOS:${{pObj.eftposPaymentAmount}}<br>
                    Cheque:${{pObj.chequePaymentAmount}}<br>
                    Credit Card:${{pObj.creditCardPaymentAmount}}({{pObj.creditCardType}})<br>
                    Cash:${{pObj.cashPaymentAmount}}<br>
                    Discount:${{pObj.discountAmount}}({{pObj.discountType}})<br>
                    <b>TOTAL:</b>${{pObj.total}}
                </td>
                <td align="center">
                 <span v-if="pObj.productPurchased=='yes'">
                   <a href="#" @click.prevent="getProductsPurchaseDetails(visitId)"> {{pObj.productPurchased}}</a>
                </span>
                <span v-if="pObj.productPurchased=='no'">
                No
                </span>
                </td>
            
            </tr>
          </table>
        
   <!-- {{pObj}}-->

   <div v-show="isProductPurchaseListSeen">
     <hr/>
      <h5>Products Purchased</h5>

      <table cellspacing="5" cellpadding="5">
          <tr> <th>Qty</th><th>Product</th><th>Unit Price</th><th>Unit Total</th></tr>
          <tr v-for="(ppObj,index) in productsPurchaseList" v-bind:key="index">
                <td>{{ppObj.qty}}</td>
                <td>{{ppObj.productId}}</td>
                <td>${{ppObj.priceBeforeDiscount}}</td>
                <td>${{getUnitTotal(ppObj.qty,ppObj.priceBeforeDiscount)}}</td>
          </tr>

      </table>
      <br>Product Total:${{getProductTotal()}}
    </div>

    <button @click="closeViewPaymentBox()" >Close</button>
  </center>
  </div>
</template>

<script>

export default {
  name: 'ViewPaymentBox',
  created:function(){
  
  },
  mounted(){

      this.$root.$refs.ViewPaymentBox = this;
   
  },
  components:{
    

  },
  props: {
    title: String
  }, computed:{
   
   
    
  },
 data: function() {
        return {
          paymentId:0,
          visitId:0,
          user:'',
          productTotal:0,
          unitTotal:0,
          productPurchased:'',
          isProductPurchaseListSeen:false,
          serverMessage:'',
          pObj:{},
          productsPurchaseList:{},
          
        };
   },
  methods:{
          shownowFunc:function(){
               
          },
          getUnitTotal:function(qty,unitPrice) {
              return qty*unitPrice;        
              

          }, getProductTotal:function() {

              let total=0;
              for(let item in this.productsPurchaseList)       {

                total+=(this.productsPurchaseList[item].qty*this.productsPurchaseList[item].priceBeforeDiscount);
              }
              
              return total;
          },
          closeViewPaymentBox:function(){
              this.productTotal=0;
              this.productsPurchaseList={};
              this.isProductPurchaseListSeen=false;
              this.$root.$refs.Appointments.showViewPaymentBox=false; 
          },
           getPayment: function(visitId){
             this.visitId=visitId;
            // alert("In getPayment method of ViewPayment Component:" + visitId);
             this.user = JSON.parse(localStorage.getItem('user'));
             
             let formData = new FormData();

             formData.append('visitId', visitId);  
              var url=process.env.VUE_APP_ROOT_API+"/payment/getByAppointmentId";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                this.pObj = response.data;
                                //alert(this.pObj);
                                
                                
                              //alert(response.data);
                              
                            })
            .catch(error => {
             this.messageFromServer =error.response.data;
            });
          },

           getProductsPurchaseDetails: function(visitId){
             this.visitId=visitId;
            // alert("In getProductsPurchaseDetails method of ViewPaymentBox Component:" + visitId);
             this.user = JSON.parse(localStorage.getItem('user'));
             
             let formData = new FormData();

             formData.append('visitId', visitId);  
              var url=process.env.VUE_APP_ROOT_API+"/product/getProductsPurchaseDetailsByVisitId";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                this.productsPurchaseList= response.data;
                                this.isProductPurchaseListSeen=true;
                               // alert(response.data);
                                
                                
                             
                              
                            })
            .catch(error => {
             this.serverMessage =error.response.data;
            });
          },
         
 

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.viewPaymentBox{
  position:absolute;
  overflow:auto;
  left:15%;top:20%;
  z-index:5000;
  width:800px;
  height:550px;
  border-style:solid;
  border-color:gray;
  border-width:1px;
  background-color:#262525;
  opacity:0.95;
  color:white;
  }
 
</style>
