<template>
  <div class="login">
    <Header></Header>
    <AppointmentReminder title="Appointment List"></AppointmentReminder>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import AppointmentReminder from '@/components/AppointmentReminder.vue'

export default {
  
    components: {
    Header,AppointmentReminder
  }
}
</script>