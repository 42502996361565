import { render, staticRenderFns } from "./AppointmentReminder.vue?vue&type=template&id=6831f963&scoped=true&"
import script from "./AppointmentReminder.vue?vue&type=script&lang=js&"
export * from "./AppointmentReminder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6831f963",
  null
  
)

export default component.exports