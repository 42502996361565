<template>
  <div class="epcRefferalDetails">
   
    <h6>EPC Referral Details </h6>
    VisitId: {{visitId}} , PatientId:{{patientId}}, 

    <!--{{patientObj}}-->
    <table align="center" v-show="isEPCReferralDetailsVisible">
    
        <tr><td align="left">Referring Doctor:</td><td align="left">{{doctorObj.doctorFirstName}} {{doctorObj.doctorLastName}}</td></tr>
        <tr><td align="left">Provider Number:</td><td align="left"> {{doctorObj.doctorProviderNumber}}</td></tr>
        <tr><td align="left">Medicare Number/IRN:</td><td align="left">{{patientObj.medicareNumber}} / {{patientObj.medicareIRNNumber}}</td></tr>
        <tr><td align="left">Referral Date:</td><td align="left">{{patientObj.epcReferralDate}}</td></tr>
        <tr><td align="left">EPC Allocated:</td><td align="left">{{patientObj.epcCountAllocated}}</td></tr>
        <tr><td align="left">EPC Balance:</td><td align="left">{{patientObj.epcCountBalance}}</td></tr>
        <tr><td align="left">Date of Service:</td><td align="left">{{aptDate}}</td></tr>
    
    </table>

    
    
    
    
  </div>
</template>

<script>

export default {
  name: 'EPCReferralDetails',
  props: {
    title: String,
    visitId:Number,
    patientId:Number,
    aptDate:String,
    doctorObj:{},
  },
  created(){
    
  },

   mounted(){

      this.$root.$refs.EPCReferralDetails = this;
      
      
  },
 data: function() {
        return {
        patientDoctorObj:{},
        patientObj:{},
        isEPCReferralDetailsVisible:false,
        serverMessage:'',
       
        };
   },
   computed: {
            getTotalPayable() {
                     return this.consultationAmount + this.gapAmount;
            }
  },
  methods:{
          getEPCDetails:function(){

            // get patient info by id
            // get doctor name and provider Number
              this.isEPCReferralDetailsVisible=true;
              this.user = JSON.parse(localStorage.getItem('user'));
              
              let formData = new FormData();

              formData.append('id', this.patientId);  
              
              var url=process.env.VUE_APP_ROOT_API+"/patient/getByPatientId";
             
             this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                //alert(response.data.content);
                                this.patientObj=response.data;
                                this.getDoctorDetails(this.patientObj.doctorId);
                            })
                                      
            .catch(error => {
             this.serverMessage =error.response.data;
            });
          
            
          },
          getDoctorDetails:function(doctorId){

                //alert("DoctorId:" + doctorId)
                 
              this.user = JSON.parse(localStorage.getItem('user'));
              
              let formData = new FormData();

              formData.append('id', doctorId);  
              
              var url=process.env.VUE_APP_ROOT_API+"/doctor/getDoctorById";
             
             this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                //alert(response.data.content);
                                this.doctorObj=response.data;
                                
                            })
                                      
            .catch(error => {
             this.serverMessage =error.response.data;
            });

          },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
