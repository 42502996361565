<template>
  <div class="header">
    
    Practitioner:

    <select @change="selectPractitioner($event)">
          <option value="na" >Select</option>
          <option value="pkaur10" selected>Pardeep Kaur</option>
          <option value="sginn10">Sarah Ginn</option>
          

    </select>
     
  </div>
  
</template>

<script>

export default {
 
  created:function(){
  
  },
  mounted(){

      this.$root.$refs.Practitioner = this;
      
  },
  components:{
    

  },
  props: {
    title: String
  },
 data: function() {
        return {
          name:'pkaur10'
          
        };
   },
  methods:{
          selectPractitioner:function(e){  
            //alert("In Practitioner component, practitioner selected : " + e.target.value);
            this.$root.$refs.AppointmentBook.practitionerName = e.target.value;
          },
                    
         
 

  }
}
</script>
