<template>

  <div class="home">
    
    <h3>Patient Summary</h3>
    <PatientSummaryC></PatientSummaryC>
  
  </div>
</template>

<script>
// @ is an alias to /src
import PatientSummaryC from '@/components/patient/PatientSummaryC.vue'
export default {
  
  components: {
  PatientSummaryC
  }
}
</script>
