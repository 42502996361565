import { render, staticRenderFns } from "./ViewClinicalNotes.vue?vue&type=template&id=3a85d834&scoped=true&"
import script from "./ViewClinicalNotes.vue?vue&type=script&lang=js&"
export * from "./ViewClinicalNotes.vue?vue&type=script&lang=js&"
import style0 from "./ViewClinicalNotes.vue?vue&type=style&index=0&id=3a85d834&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a85d834",
  null
  
)

export default component.exports