<template>

 
    
    <b-container >
    <b-row col="12">
     <b-col align-h="start" cols="2 "> <h4>Correspondence</h4> </b-col>
     <b-col cols="8"> <SendSMS></SendSMS></b-col>
      <b-col cols="2">  
      <SMSLogs @getSMSLogs="fireMethod($event)"></SMSLogs>
       
      </b-col>
     
    </b-row>
  
     <b-table striped hover :items="smsLogs" :fields="fields" thead-class="green-bg bg-light text-black"></b-table>
  
    </b-container>
  
   
   

</template>

<script>
// @ is an alias to /src
import SendSMS from '@/components/correspondence/SendSMS.vue'
import SMSLogs from '@/components/correspondence/SMSLogs.vue'
export default {
  
  components: {
  SendSMS,SMSLogs
  },
 data: function() {
        return {
         smsLogs:[],
         items:this.smsLogs,
         
         fields: [
          {
            key: 'id',
            sortable: false
          },
          {
            key: 'mobile',
            sortable: false
          },
          {
            key: 'message',
            sortable: false
          },
          
          {
            key: 'status',
            label: 'Status',
            sortable: false,
            
          },
           {
            key: 'timestamp',
            label: 'Timestamp',
            sortable: true,
            
          }
        ],
        headVariant:'dark'
        }
    },
  methods:{

      fireMethod:function(smsLogs){

         // alert("In fireMethod()");
          this.smsLogs=smsLogs;
          
      }

  }
}
</script>
