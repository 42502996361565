<template>
  <div class="login">
    
    <Login title="Login"></Login>
    
  </div>
</template>
<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'

export default {
  
    components: {
    Login
  }
}
</script>