<template>

 
    <div class="home">
    
          <ViewClinicalNotes title="Clinical Notes"  v-show="showClinicalNotesBox"></ViewClinicalNotes>
          <GenerateLetterBox title="Generate Letter"  v-show="showGenerateLetterBox"></GenerateLetterBox>
          
          <AddClinicalNotes title="Add Clinical Notes"  v-show="showAddClinicalNotesBox"></AddClinicalNotes>
          <ViewPaymentBox title="Payment"  v-show="showViewPaymentBox"></ViewPaymentBox>
          <EnterPaymentBox title="Enter Payment"  :patientId="patientId" v-show="showEnterPaymentBox"></EnterPaymentBox>
          <ViewPdf title="View PDF Document"    v-show="isViewPdfVisible"></ViewPdf>

         <h3>Appointments</h3>
         {{serverMessage}}
        <!-- {{appointmentList}}-->
        <table cellspacing="10" cellpadding="10">
        <tr> <th>VisitId</th><th>Date/Time</th><th>Location</th><th>Practioner</th><th>Appointment Type</th><th>Checked In</th><th>Payment Entered</th><th>Clinical Notes</th><th>Generate Letter</th></tr>
         <tr v-for="(appointment,index) in appointmentList" v-bind:key="index">
        
        <td>{{appointment.visitId}}</td>
        <td>{{appointment.date}} / {{appointment.startTime}}</td>
        <td>{{appointment.clinicLocation}}</td>
        <td>{{appointment.practitionerName}}</td>
        <td>{{appointment.appointmentType}}</td>
        <td>{{appointment.checkedIn}}</td>
        <td>
            <p v-if="(appointment.paymentEntered==='yes')&&(appointment.checkedIn==='yes')">
                 <a href="#" @click.prevent="viewPayment(appointment.visitId)"> View</a> |
                  <a href="#" @click.prevent="deletePayment(appointment.visitId)"> Delete</a> |
                   <a href="#" @click.prevent="generateInvoice(appointment.visitId)"> Invoice/Receipt</a>
            </p>
            <p v-else-if="appointment.checkedIn==='no'">
                 NA
            </p>
            <p v-else>
                 <a href="#" @click.prevent="enterPayment(appointment.visitId,appointment.date)"> Enter</a>
            </p>
        </td>
     
        <td align="left">
            <p v-if="(appointment.checkedIn==='no')">
                NA
            </p> 
            <p v-else-if="(appointment.checkedIn==='yes')&&(appointment.clinicalNotesPresentingComplaint==='not yet entered')">
               <a href="#" @click.prevent="addClinicalNotes(appointment.visitId)"> Enter Notes</a>
            </p> 
            <p v-else>
                <a href="#" @click="viewClinicaNotes(appointment.clinicalNotesPresentingComplaint,appointment.clinicalNotesObservation,appointment.clinicalNotesTreatment)"> View Notes</a>
            
            </p>
        </td>
        <td>
              <p v-if="(appointment.checkedIn==='yes')">
                    
              <button   @click.prevent="generateLetter(appointment.visitId)">Generate</button>

              </p>
        </td>
        </tr>

        </table>

  Page: {{pageNumber+1}}
      <!--{{pageable}}
         <button value="Send" @click="getAppointments">Send</button><br>-->

         <button value="Back" @click="getPreviousPage">Back</button>
         <button value="Next" @click="getNextPage">Next</button>
        
    </div>
    
   
   

</template>

<script>
import ViewClinicalNotes from '@/components/clinicalnotes/ViewClinicalNotes.vue'
import GenerateLetterBox from '@/components/correspondence/GenerateLetterBox.vue'
import AddClinicalNotes from '@/components/clinicalnotes/AddClinicalNotes.vue'
import ViewPaymentBox from '@/components/payment/ViewPaymentBox.vue'
import EnterPaymentBox from '@/components/payment/EnterPaymentBox.vue'
import ViewPdf from '@/components/ViewPdf.vue'

export default {
  name: 'PatientAppointments',
  
   components: {
  ViewClinicalNotes,AddClinicalNotes,ViewPaymentBox,EnterPaymentBox,GenerateLetterBox,ViewPdf
  },
 
 data: function() {
        return {
          appointmentList:[],
          messageStatus:'',
          page:0,
          size:5,
          patientId:JSON.parse(localStorage.getItem('currentPatientId')),
          visitId:0,
          user:'',
          totalPages:0,
          pageable:{},
          pageNumber:0,
          showClinicalNotesBox:false,
          showAddClinicalNotesBox:false,
          showViewPaymentBox:false,
          showEnterPaymentBox:false,
          showGenerateLetterBox:false,
          isViewPdfVisible:false,
          serverMessage:'',
        };
   },
  methods:{
          generateInvoice:function(visitId){
          // alert("In Appointments view , generate Invoice method,visitId:"+visitId);
            //generate the Invoice

             this.user = JSON.parse(localStorage.getItem('user'));
              this.patientId=JSON.parse(localStorage.getItem('currentPatientId'));
              let formData = new FormData();

              formData.append('letterType',"Receipt_Invoice"); 
              formData.append('visitId', visitId); 
              formData.append('patientId', this.patientId); 
              formData.append('saveLetter', "no"); 
              
               var url=process.env.VUE_APP_ROOT_API+"/fax/generatePdfLetter";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                //alert(response.data);
                               // this.serverMessage = response.data;
                                if(response.data=="Receipt_Invoice Generated"){

                                  // open view pdf with invoice generated
                                    this.isViewPdfVisible=true;
                                    this.$root.$refs.ViewPdf.viewPdfDocFunc("Receipt_Invoice",visitId);
                                }
                                                            
                            })
            .catch(error => {
             this.serverMessage =error.response.data;
            });

            
          },
          getAppointments: function(){
              
              this.user = JSON.parse(localStorage.getItem('user'));
              //this.patientId=JSON.parse(localStorage.getItem('currentPatientId'));
              //alert("In get Appointments Method");
              let formData = new FormData();

              formData.append('page', this.page);  
              formData.append('size', this.size);  
              formData.append('patientId', this.patientId);  
              
              var url=process.env.VUE_APP_ROOT_API+"/appointment/getAppointments";
             
             this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                //alert(response.data.content);
                                //his.messageStatus = response.data.content;
                                this.appointmentList= response.data.content;
                                this.pageable=response.data.pageable;
                                this.pageNumber=response.data.pageable.pageNumber;
                            })
                                      
            .catch(error => {
             this.messageStatus =error.response.data;
            });
          },
          getNextPage:function(){
           // alert("In getNextPage method");
            this.page++;
            this.getAppointments();
          },
          getPreviousPage:function(){
           // alert("In getPreviousPage method");
            this.page--;
            this.getAppointments();
          },
          generateLetter:function(visitId){
             // alert("Generate Letter Function,visitId:"+visitId);
                 this.showGenerateLetterBox=true;
                 this.$root.$refs.GenerateLetterBox.visitId=visitId;
                 
          },
          viewClinicaNotes:function(presentingComplaint,observation,treatment){
            //e.preventDefault();
            //alert("In viewClinicaNotes method" + presentingComplaint);
            this.showClinicalNotesBox=true;
            this.$root.$refs.ViewClinicalNotes.populateClinicalNotes(presentingComplaint,observation,treatment);
            
          },
         addClinicalNotes:function(visitId){
            
          //  alert("In addClinicalNotes method,visitId:" + visitId);
            this.showAddClinicalNotesBox=true;
            this.$root.$refs.AddClinicalNotes.initialize(visitId);
            
          },
          viewPayment:function(visitId){
            
         // alert("In viewPayment method,visitId:" + visitId);
          this.showViewPaymentBox=true;
           this.$root.$refs.ViewPaymentBox.getPayment(visitId); 
            
          },
           deletePayment:function(visitId){
            
         alert("In deletePayment method,visitId:" + visitId);
           this.user = JSON.parse(localStorage.getItem('user'));
              
              let formData = new FormData();

              
              formData.append('visitId', visitId);  
              
              var url=process.env.VUE_APP_ROOT_API+"/payment/deletePaymentByVisitId";
             
             this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                
                                this.serverMessage = response.data;
                                this.getAppointments();
                            })
                                      
            .catch(error => {
             this.serverMessage =error.response.data;
            });
            
          },
          enterPayment:function(visitId,aptDate){
            this.$root.$refs.EnterPaymentBox.shownowFunc(visitId,aptDate);
            
         // alert("In enterPayment method,visitId:" + visitId);
           // this.showEnterPaymentBox=true;
            
          },
  },
  mounted(){
    this.$root.$refs.Appointments = this;
    this.getAppointments();
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .patientSearch{
    position:absolute;
    left:15%;top:10%;
    z-index:5000;
    width:400px;
    height:450px;
    border-style:solid;
    border-color:gray;
    border-width:1px;
    background-color:#262525;
    opacity:0.95;
    color:white;
    }
</style>