<template>

  <div class="home">
    <Header></Header>
     <b-container >
        <b-row col="12">
         <b-col cols="2"><h4>Patient</h4></b-col>
         <b-col cols="7"> <PatientSearch></PatientSearch></b-col>
          <b-col cols="3">Current Patient:<b>{{currentPatientName}}</b>
                  <br>m:<a :href="`tel:${ currentPatientMobile }`">{{currentPatientMobile}}</a>
                  <br>p:<a :href="`tel:${ currentPatientPhone }`">{{currentPatientPhone}}</a>
                 
          </b-col>
        </b-row>
    </b-container>
    <PatientHeader></PatientHeader>
    <hr>


    <!-- Outlet for children routes -->
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import PatientSearch from '@/components/patient/PatientSearch.vue'
import PatientHeader from '@/components/patient/PatientHeader.vue'

export default {
  
  components: {
  Header,PatientSearch,PatientHeader
  },
  mounted: function()   {

      this.$root.$refs.Patient = this;
      this.currentPatientMobile=localStorage.getItem('currentPatientMobile');
      this.currentPatientName=localStorage.getItem('currentPatientName');
      this.currentPatientObj=JSON.parse(localStorage.getItem('currentPatientObj'));
      this.currentPatientPhone=this.currentPatientObj.patientPhoneNumber;
      //this.initialize();
  },
  data: function() {
        return {
        currentPatientName:'',
        currentPatientMobile:'',
        currentPatientPhone:'',
        currentPatientObj:{},
        }
        },
  methods:{
        initialize: function(patientMobileNumber){
          alert("jj");
          this.currentPatientMobile=patientMobileNumber;

        },
  }      
  

}
</script>
