<template>

  <div class="home">
    
    <h3>Patient SMS Logs</h3>
    <SMSLogs></SMSLogs>
  
  </div>
</template>

<script>
// @ is an alias to /src
import SMSLogs from '@/components/correspondence/SMSLogs.vue'
export default {
  
  components: {
  SMSLogs
  }
}
</script>
