<template>
  <div class="viewPdf" align="left">
  <br>

 <SendEFax title="Send EFax"   :letterType="letterType" :visitId="visitId" :patientId="patientId" v-show="isSendEFaxBoxVisible"></SendEFax>  
 <SendEmail title="Send Email"   :letterType="letterType" :visitId="visitId" :patientId="patientId" v-show="isSendEmailBoxVisible"></SendEmail>  
<button @click.prevent="emailNow()" style="margin-left:10px;">Email Now</button> <button @click.prevent="faxNow()">Fax Now</button> <button @click.prevent="faxInBatch('faxInbatch')">Fax In Batch</button>  

<button @click="closeViewPdf()">Close</button><br/>
{{serverMessage}}

<pdf :src="pdfDocLink"></pdf>
       
     
  </div>
  
</template>

<script>
import pdf from 'vue-pdf'
import SendEFax from '@/components/correspondence/SendEFax.vue'
import SendEmail from '@/components/correspondence/SendEmail.vue'

export default {
  name: 'ViewPdf',
  mounted(){

      this.$root.$refs.ViewPdf = this;
  },
  components:{
    pdf,SendEFax,SendEmail
  },
  
   data: function() {
            return {
            
            pdfDocLink:"",
            letterType:'na',
            visitId:0,
            patientId:JSON.parse(localStorage.getItem('currentPatientId')),
            user:'',
            serverMessage:'',
            isSendEFaxBoxVisible:false,
            isSendEmailBoxVisible:false,
            saveLetter:'yes',
            };
        },methods:{
         

          viewPdfDocFunc: function(letterType,visitId){

            //alert("In viewPdfDoc function letterType:" + letterType +",visitId:"+visitId);
            this.letterType=letterType;
            this.visitId=visitId;
            this.user = JSON.parse(localStorage.getItem('user'));

              this.pdfDocLink = pdf.createLoadingTask({
                url:process.env.VUE_APP_ROOT_API+"/fax/getPdfPage?patientId="+this.patientId +"&visitId=" + visitId +"&letterType=" +letterType ,
              
                httpHeaders: {
                    Authorization: 'Bearer '  + this.user,
                   
                },
                
                 })
     },
          closeViewPdf:function(){
            this.pdfDocLink="";
            this.serverMessage="";
            this.$root.$refs.Appointments.isViewPdfVisible=false;
            this.$root.$refs.GenerateLetterBox.isViewPdfBtnDisabled=true;
          },
        
        faxInBatch:function(){
              this.user = JSON.parse(localStorage.getItem('user'));
              this.patientId=JSON.parse(localStorage.getItem('currentPatientId'));
              let formData = new FormData();

              formData.append('letterType', this.letterType); 
              formData.append('visitId', this.visitId); 
              formData.append('patientId', this.patientId); 
              
               var url=process.env.VUE_APP_ROOT_API+"/fax/faxInBatch";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                 //alert(response.data);
                                this.serverMessage = response.data;
                                
                                                            
                            })
            .catch(error => {
             this.serverMessage =error.response.data;
            });


          },
        faxNow:function(){

            //alert("In sendEFax method of ViewPdf component");
            this.isSendEFaxBoxVisible=true;
            this.$root.$refs.SendEFax.getDoctorFaxNumber();
        },
        emailNow:function(){

            //alert("In sendEFax method of ViewPdf component");
            this.isSendEmailBoxVisible=true;
            this.$root.$refs.SendEmail.setFocus();
            
        },
   
      },
  
  props: {
    title: String,
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

.viewPdf{
  color:white;
  
  position:absolute;
 
  border-style:solid;
  border-color:gray;
  border-width:1px;
 background-color:white;
 width:40%;height:82%;
 left:50%;top:10%;
  overflow-y:scroll;
  z-index:9000;
}
h3 {
  margin: 10px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
