<template>
  <div class="bookAppointmentBox">
    <h3>{{ title }}</h3>
   Date:{{appointmentDate}}, Time:{{startTime}},Location:{{clinicLocation}},Practitioner:{{practitionerName}},Patient Id:{{patientId}}
   <br>
   <center>
    <table>
    <tr><td> <PatientSearchStandalone/></td>
   <td><select v-model="appointmentType">
				<option value="Standard Consultation" >Standard Consultation</option>
				<option value="New Patient" >New Patient</option>
				<option value="Home Visit" >Home Visit</option>
				<option value="Nail Surgery" >Nail Surgery</option>
				<option value="Online Booking" >Online Booking</option>				

	</select>
    </td>
    <td>
			<select v-model="appointmentDuration" @change="selectAppointmentDuration($event)">
				<option value="0:10">10 Mins</option>
				<option value="0:20">20 Mins</option>
				<option value="0:30">30 Mins</option>
				<option value="0:40">40 Mins</option>
			</select>	
    </td>
    </tr>
    </table>
    </center>
    <br> <button @click="bookAppointment">Book</button>  
    
    <a href="#" @click.prevent="isregisterPatientBoxSeen=true">New Patient</a><br>
    <div v-show="isregisterPatientBoxSeen">

        <select v-model="patientSalutation">
            <option value="na">Select</option>
            <option value="Ms">Ms</option>
            <option value="Miss">Miss </option>
            <option value="Mr">Mr</option>
				</select>

        <input type="text"  ref="patientFirstNameRef" v-model="patientFirstName" placeholder="Firstname"/>
     
        <input type="text"  ref="patientLastNameRef" v-model="patientLastName" placeholder="Lastname"/><br>

        <input type="text"  ref="patientTelephoneNumberRef" v-model="patientTelephoneNumber" placeholder="Landline"/>

        <input type="text"  ref="patientMobileNumber" v-model="patientMobileNumber" placeholder="Mobile Number"/>

        <input type="button" @click.prevent="registerNewPatient()" value="Register"/>

    </div>
    <button @click="closeBookAppointmentBox">Close</button>
   {{serverMessage}}
  </div>
</template>

<script>
import PatientSearchStandalone from '@/components/patient/PatientSearchStandalone.vue'

export default {
  name: 'BookAppointmentBox',
  props: {
    title: String,
    
    },
components: {
  PatientSearchStandalone
  },
 data: function() {
        return {
          user:'',
          startTime:'',
          practitionerName:'',
          clinicLocation:'',  
          patientId:0,
          appointmentDuration:'0:20',
          appointmentType:'Standard Consultation',
          isregisterPatientBoxSeen:false,
          appointmentDate:'',
          patientSalutation:'na',
          patientFirstName:'',
          patientLastName:'',
          patientTelephoneNumber:'',
          patientMobileNumber:'',
          serverMessage:'',
          
        
        
        };
   },
  methods:{

          closeBookAppointmentBox:function(e){
            e.preventDefault();
            //alert("In closeBookAppointmentBox method");
            this.isregisterPatientBoxSeen=false;
            this.patientFirstName='';
            this.patientLastName='';
            this.patientTelephoneNumber='';
            this.patientMobileNumber='';
            this.isregisterPatientBoxSeen=false;
            this.$root.$refs.PatientSearchStandalone.searchPatientString='';
            this.$root.$refs.AppointmentBook.getAppointments();
            this.$root.$refs.AppointmentBook.showBookAppointmentBox=false;
            
          },
          initialize:function(startTime,dateYYYYMMDD,clinicLocation,practitionerName){
            
                this.appointmentDate=dateYYYYMMDD;
                this.startTime=startTime;
                this.clinicLocation=clinicLocation;
                this.practitionerName=practitionerName;
                
          },
          selectAppointmentType:function(e){  
            //alert("In Practitioner component, practitioner selected : " + e.target.value);
            this.appointmentType= e.target.value;
          },
          selectAppointmentDuration:function(e){  
            //alert("In Practitioner component, practitioner selected : " + e.target.value);
            this.appointmentDuration = e.target.value;
          },
          bookAppointment:function(){  

              //alert("In bookAppointment method of bookAppointmentBox component");
              this.user = JSON.parse(localStorage.getItem('user'));
              let formData = new FormData();
              formData.append('patientId', this.patientId);  
              formData.append('practitionerName', this.practitionerName); 
              formData.append('date', this.appointmentDate); 
              formData.append('clinicLocation', this.clinicLocation); 
              formData.append('startTime', this.startTime);
              formData.append('appointmentDuration', this.appointmentDuration);
              formData.append('appointmentType', this.appointmentType);

              var url=process.env.VUE_APP_ROOT_API+"/appointment/bookAppointment";
             
               this.$http.post(url,formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                
                               alert(response.data);
                              
                              
                            })
            .catch(error => {
             this.messageFromServer =error.response.data;
            });
          }  ,
           displayRegisterPatientBox:function(){
            this.isregisterPatientBoxSeen=true;
            
          },
          registerNewPatient:function(){

            alert("In BookAppointmentBox component, registerNewPatient method:");
            this.user = JSON.parse(localStorage.getItem('user'));
            let formData = new FormData();

              formData.append('patientSalutation', this.patientSalutation);  
              formData.append('patientFirstName', this.patientFirstName);  
              formData.append('patientLastName', this.patientLastName);  
              formData.append('patientTelephoneNumber', this.patientTelephoneNumber);  
              formData.append('patientMobileNumber', this.patientMobileNumber);  
              var url=process.env.VUE_APP_ROOT_API+"/patient/registerNewPatient";
              this.$http.post(url, formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                this.serverMessage = response.data;
                               
                              alert(response.data);
                              
                            })
            .catch(error => {
             this.serverMessage =error.response.data;
            });

          },
          
  },
  mounted(){
    this.$root.$refs.BookAppointmentBox = this;
    
  }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .bookAppointmentBox{
    position:absolute;
    left:30%;top:25%;
    z-index:5000;
    width:700px;
    height:450px;
    border-style:solid;
    border-color:gray;
    border-width:1px;
    background-color:#262525;
    opacity:0.90;
    color:white;
    
    
    }
    .textInPopup{

        margin:2%;
    }
    h3{
        margin-top:2%;
    }
</style>