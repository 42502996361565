<template>
  <div class="clinicalNotesBox">
    <h3>{{ title }}</h3> VisitId:{{visitId}}
    <p align="left" class="textInPopup">
        <b>Presenting Complaint:</b>
        <textarea v-model="presentingComplaint" rows="5" cols="40" ref="pc"/><br>
        <b>Observation</b>:
        <textarea v-model="observation" rows="5" cols="40"/> <br>
        <b>Treatment Provided:</b>
         <textarea v-model="treatment" rows="5" cols="40"/><br>
    </p>  

    <!--{{presentingComplaint}} |  {{observation}} |  {{treatment}}-->
     <button @click.prevent="addClinicalNotes">Add</button>  
    <button @click.prevent="closeClinicalNotesBox">Close</button>
    <br>
<button @click.prevent="getPreviouslyEnteredClinicalNotes()">Previous Notes</button>


    {{serverMessage}}
  </div>
</template>

<script>
export default {
  name: 'AddClinicallNotes',
  props: {
    title: String,
    
    },
 data: function() {
        return {
          presentingComplaint:'',
          observation:'',
          treatment:'',  
          visitId:0,
          patientId:JSON.parse(localStorage.getItem('currentPatientId')),
          serverMessage:'',
          appointment:{},
        };
   },
  methods:{
        initialize:function(visitId){
          //alert("In AddClinicalNotes component initialize method, visitId:" + visitId);
           this.visitId=visitId;
           this.$nextTick(() => this.$refs.pc.focus());
            
          },
          closeClinicalNotesBox:function(){
            
           // alert("In closeClinicalNotesBox method");
            this.$root.$refs.Appointments.showAddClinicalNotesBox=false;
            this.observation='';
            this.presentingComplaint=''; 
            this.treatment='';
            
          },
           addClinicalNotes:function(){
            //alert(this.visitId)
            this.user = JSON.parse(localStorage.getItem('user'));
            let formData = new FormData();
            formData.append('visitId', this.visitId);
            formData.append('presentingComplaint', this.presentingComplaint);
            formData.append('observation', this.observation);
            formData.append('treatment', this.treatment);
            var url=process.env.VUE_APP_ROOT_API+"/appointment/addClinicalNotes";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                this.serverMessage= response.data;
                                
                              
                            })
            .catch(error => {
             this.serverMessage=error.response.data;
            });
        
        },
         getPreviouslyEnteredClinicalNotes:function(){
            //alert(this.visitId)
            this.user = JSON.parse(localStorage.getItem('user'));
            let formData = new FormData();
            formData.append('visitId', this.visitId);
            formData.append('patientId', this.patientId);
           
            var url=process.env.VUE_APP_ROOT_API+"/appointment/getPreviouslyEnteredClinicalNotes";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                this.appointment= response.data;
                                this.presentingComplaint=this.appointment.clinicalNotesPresentingComplaint;
                               this.observation=this.appointment.clinicalNotesObservation;
                               this.treatment=this.appointment.clinicalNotesTreatment;
                              
                            })
            .catch(error => {
             this.serverMessage=error.response.data;
            });
        
        }
         

          
  },
  mounted(){
    this.$root.$refs.AddClinicalNotes = this;
    
  }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .clinicalNotesBox{
    position:absolute;
    left:35%;top:5%;
    z-index:5000;
    width:600px;
    height:750px;
    border-style:solid;
    border-color:gray;
    border-width:1px;
    background-color:#262525;
    opacity:0.90;
    color:white;
    
    
    }
    .textInPopup{

        margin:2%;
    }
    h3{
        margin-top:2%;
    }
</style>