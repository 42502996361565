<template>
  <div class="clinicalNotesBox">
    <h3>{{ title }}</h3>
    <p align="left" class="textInPopup">
        <b>Presenting Complaint:</b>{{presentingComplaint}}<br><br>
        <b>Observation</b>:{{observation}}<br><br>
        <b>Treatment Provided:</b>{{treatment}}<br><br>
    </p>    
    <button @click="closeClinicalNotesBox">Close</button>
  </div>
</template>

<script>
export default {
  name: 'ViewClinicalNotes',
  props: {
    title: String,
    
    },
 data: function() {
        return {
          presentingComplaint:'',
          observation:'',
          treatment:'',  
          
        
        
        };
   },
  methods:{

          closeClinicalNotesBox:function(e){
            e.preventDefault();
           // alert("In closeClinicalNotesBox method");
            this.$root.$refs.Appointments.showClinicalNotesBox=false;
            
          },
          populateClinicalNotes:function(presentingComplaint,observation,treatment){
                this.presentingComplaint=presentingComplaint;
                this.observation=observation;
                this.treatment=treatment;
          },

          
  },
  mounted(){
    this.$root.$refs.ViewClinicalNotes = this;
    
  }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .clinicalNotesBox{
    position:absolute;
    left:35%;top:10%;
    z-index:5000;
    width:400px;
    height:450px;
    border-style:solid;
    border-color:gray;
    border-width:1px;
    background-color:#262525;
    opacity:0.90;
    color:white;
    
    
    }
    .textInPopup{

        margin:2%;
    }
    h3{
        margin-top:2%;
    }
</style>