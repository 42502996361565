<template>

  <div class="EnterPaymentBox">
  
        <h3>{{title}} - VisitId :{{visitId}} - PatientId:{{patientId}}</h3>
       
   <br>
   {{serverMessage}}
   <ConsultationCodeSearch  @getConsultationAmount="getCodeAmount"/>
  
   <ProductSearch @getProductTotal="getProductTotal"/>
   <EPCReferralDetails :visitId="visitId" :patientId="patientId"  :aptDate="aptDate" v-show="isEPCReferralComponentVisible"/>
   
  
   <br> 
   Gap Amount:<input type="text" v-model="gapAmount" size="4"><br>

  Consultation Amount:${{consultationAmount}}<br>
  Consultation Amount Payable:${{consultationAmountPayable}}<br>
  <HealthFundSearch/>
  Insurance:<input type="text" v-model="healthInsurancePayment" size="4">
  Cash:<input type="text" v-model="cashPayment" size="4">
  EFTPOS:<input type="text" v-model="eftposPayment" size="4">
  Credit Card:<input type="text" v-model="creditCardPayment" size="4">
  <select v-model="creditCardType">
          <option value="na" >Select</option>
          <option value="visa">Visa</option>
          <option value="mastercard">Mastercard</option>
          <option value="amex">AMEX</option>
    </select>
   <!-- {{creditCardType}}-->
  <br>
  TOTAL Payable:${{getTotalPayable}}<br>
  TOTAL Paid:${{getTotalPaid}}<br>
  OUTSTANDING:${{getTotalOutstanding}}<br>

<!--Product Cart:{{productCart}}<br>
{{pObj}}
-->
    <button @click.prevent="submitPayment()" :disabled="isSubmitBtnDisabled">Submit</button> <button @click="closeViewPaymentBox()" >Close</button>
  </div>
</template>

<script>
import ConsultationCodeSearch from '@/components/payment/ConsultationCodeSearch.vue'
import ProductSearch from '@/components/payment/ProductSearch.vue'
import EPCReferralDetails from '@/components/payment/EPCReferralDetails.vue'
import HealthFundSearch from '@/components/payment/HealthFundSearch.vue'

export default {
  name: 'EnterPaymentBox',
  created:function(){
  
  },
  mounted(){

      this.$root.$refs.EnterPaymentBox = this;
       
  },
  components:{
    
    ConsultationCodeSearch,EPCReferralDetails,HealthFundSearch,ProductSearch

  },

  props: {
    title: String,
    patientId:Number,
  },
 data: function() {
        return {
            user:'',
            paymentId:0,
            visitId:0,
            aptDate:'',
            productPurchased:'',
            pObj:{},
            isEPCReferralComponentVisible:false,
            gapAmount:0,
            consultationCode:'',
            codeDescription:this.$root.$refs.ConsultationCodeSearch.currentConsultationCodeObj.codeDescription,
            consultationAmount:0,
            consultationAmountPayable:0,
            totalPayable:0,
            totalPaid:0,
            totalOutstanding:0,
            healthInsurancePayment:0,
            fundName:this.$root.$refs.HealthFundSearch.healthFundSearchString,
            cashPayment:0,
            eftposPayment:0,
            creditCardPayment:0,
            creditCardType:'na',
            serverMessage:'',
            isSubmitBtnDisabled:false,
            productTotal:0,
            productCart:[],



          
        };
   },
   computed:{
    getTotalPayable:function() {
      return (Number(this.consultationAmountPayable) + Number(this.gapAmount)+Number(this.productTotal));
    },
    getTotalPaid:function() {
      return (Number(this.healthInsurancePayment) + Number(this.cashPayment) + Number(this.eftposPayment) + Number(this.creditCardPayment));
    },
    getTotalOutstanding:function() {
      return (Number(this.getTotalPayable) - Number(this.getTotalPaid));
    },
  },
    
  methods:{
    getCodeAmount:function(codeAmount,amountPayable){

        this.consultationAmount=codeAmount;
        this.consultationAmountPayable=amountPayable;
    },
     getProductTotal:function(productTotal,productCart){

        this.productTotal=productTotal;
        this.productCart=productCart;
    },
    submitPayment:function(){

                this.user = JSON.parse(localStorage.getItem('user'));

                this.pObj.visitId=this.visitId;
                this.pObj.patientId=this.patientId;
                this.pObj.code=this.$root.$refs.ConsultationCodeSearch.currentConsultationCodeObj.code;
                this.pObj.codeDescription=this.$root.$refs.ConsultationCodeSearch.currentConsultationCodeObj.codeDescription;
                this.pObj.codeAmount=this.consultationAmount;
                this.pObj.gapPaymentAmount=this.gapAmount;
                this.pObj.amountCoveredByHealthInsurance=this.healthInsurancePayment;
                //this.pObj.healthInsuranceProvider=this.fundName;
                this.pObj.healthInsuranceProvider=this.$root.$refs.HealthFundSearch.healthFundSearchString;
                alert(this.pObj.healthInsuranceProvider);
                this.pObj.eftposPaymentAmount=this.eftposPayment;
                this.pObj.creditCardPaymentAmount=this.creditCardPayment;
                this.pObj.creditCardType=this.creditCardType;
                this.pObj.cashPaymentAmount=this.cashPayment;
                this.pObj.outstandingAmount=this.getTotalOutstanding;

                if(this.productCart.length>0){
                  this.pObj.productPurchased="yes";
                }
                else{this.pObj.productPurchased="no";	}
                this.pObj.discountAmount=0;
                this.pObj.discountType="na";
                this.pObj.total=this.totalPayable//total(consult+products) payable after discount
                this.pObj.reconciled="no";
                this.totalPayable=0;
                this.totalOutstanding=0;
                //this.pObj.productCart=this.productCart;

                let formData = new FormData();
                formData.append('paymentObj', JSON.stringify(this.pObj)); 
                formData.append('productCart', JSON.stringify(this.productCart));                 

                var url=process.env.VUE_APP_ROOT_API+"/payment/recordPaymentAndProducts";

                this.$http.post(url,formData ,

                                    {
                                        headers: {
                                                'Authorization': 'Bearer ' + this.user
                                                }
                                    })
                .then(response => {
                            //alert(response.data.content);
                            this.serverMessage=response.data;
                            this.isSubmitBtnDisabled=true;
                        })
                               
                .catch(error => {
                this.serverMessage =error.response.data;
                });

    
            },
          shownowFunc:function(visitId,aptDate){
               // alert("In show now func");
               this.visitId=visitId;
               this.aptDate=aptDate;
               this.$root.$refs.Appointments.showEnterPaymentBox=true; 
               this.$root.$refs.ConsultationCodeSearch.setFocus();
          },
          closeViewPaymentBox:function(){
                    this.$root.$refs.Appointments.showEnterPaymentBox=false; 
                    this.isEPCReferralComponentVisible=false; 
                    this.gapAmount=0;
                    this.consultationAmount=0;
                    this.consultationAmountPayable=0;
                    this.totalPayable=0;
                    this.totalPaid=0;
                    this.totalOutstanding=0;
                    this.healthInsurancePayment=0;
                    this.cashPayment=0;
                    this.eftposPayment=0;
                    this.creditCardPayment=0;
                    this.creditCardType="na";
                    this.serverMessage="";
                    this.isSubmitBtnDisabled=false;
                    this.$root.$refs.HealthFundSearch.healthFundSearchString='';
                    this.$root.$refs.EPCReferralDetails.isEPCReferralDetailsVisible=false; 
                    this.$root.$refs.EPCReferralDetails.patientObj={}; 
                    this.$root.$refs.ConsultationCodeSearch.consultationCodeList=[]; 
                    this.$root.$refs.ConsultationCodeSearch.currentConsultationCodeObj={}; 
                    this.$root.$refs.ProductSearch.currentProductObj={}; 
                    this.$root.$refs.ProductSearch.productCart=[]; 
                    this.$root.$refs.ProductSearch.productCartTotal=0; 
                    this.productCart=[];
                    this.pObj={};
                    this.$root.$refs.Appointments.getAppointments();
          },
           
         
 

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.EnterPaymentBox{
        overflow: auto;
        position:absolute;
        left:20%;top:10%;
        z-index:5000;
        width:800px;
        height:700px;
        border-style:solid;
        border-color:gray;
        border-width:1px;
        background-color:#262525;
        opacity:0.95;
        color:white;
  }
 
</style>
