<template>
  <div class="sendSMS">
    <h1>{{ title }}</h1>
    <form>
       
        <input type="text"  v-model="message" placeholder="Enter message (70 chars max)"> &nbsp;
        <b-button type="submit" value="Send" @click="sendSMS">Send</b-button>
    </form>
    {{messageStatus}}
  </div>
</template>

<script>
export default {
  name: 'SendSMS',
  props: {
    title: String
  },
 data: function() {
        return {
          credentials:{},
          message: '',
          messageStatus:'',
          user:''
        
        
        };
   },
  methods:{

          sendSMS: function(e){
              e.preventDefault();
              
              this.user = JSON.parse(localStorage.getItem('user'));
              this.credentials.message=this.message;
              this.credentials.patientMobile=JSON.parse(localStorage.getItem('currentPatientMobile'));
              //alert("Current Patient Mobile:"+JSON.parse(localStorage.getItem('currentPatientMobile')));
              var url=process.env.VUE_APP_ROOT_API+"/correspondence/sendMessage";
             
             this.$http.post(url, this.credentials,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                this.messageStatus = response.data;
                               
                            })
                                      
            .catch(error => {
             this.messageStatus =error.response.data;
            });
          }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
