<template>
  <div class="header">
    <router-link to="/patient/patientsummary">Patient Summary</router-link> |
    <router-link to="/patient/patientdetails">Patient Details</router-link> |
    <router-link to="/patient/appointments">Appointments</router-link> |
    <router-link to="/patient/correspondence">Correspondence</router-link> |
    
  </div>
</template>

<script>
export default {
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
