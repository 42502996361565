<template>
  <div class="consultationCodeSearch">
   
    <form>
        <label for="searchConsultationCodeString">Consultation Code:</label>
        <input type="text" name="searchConsultationCodeString" ref="searchConsultationCodeRef" v-model="searchConsultationCodeString" @keyup="getConsultationCodeList()"/>
        <table v-show="seen" >
         
         <tr v-for="(item,index) in consultationCodeList" :key="index">&nbsp;&nbsp;<a href="#" class="autocomplete" @click.prevent="consultationCodeSelectedFunc(item.id,index)">({{ item.code}}) - {{item.codeDescription}}</a>&nbsp;&nbsp;</tr>
         
         </table>
    </form>

  Code:{{currentConsultationCodeObj.code}} - {{currentConsultationCodeObj.codeDescription}}, Amount:${{currentConsultationCodeObj.codeAmount}}
    
  </div>
</template>

<script>

export default {
  name: 'ConsultationCodeSearch',
  props: {
    title: String
  },
  created(){
    
  },

   mounted(){

      this.$root.$refs.ConsultationCodeSearch = this;
      
      
  },
 data: function() {
        return {
          consultationCodeList:[],
          currentConsultationCodeObj:{},
          searchConsultationCodeString:'',
          user:'',
          seen:false,
          amountPayable:0,
        };
   },
  methods:{
          consultationCodeSelectedFunc:function(id,index){

              //alert("Address Selected" +item);
            this.currentConsultationCodeObj=this.consultationCodeList[index];
             if(this.currentConsultationCodeObj.paymentType==='Medicare'){

                //  alert("Medicare Payment");
                  // get EPC Referral Details
                  this.$root.$refs.EnterPaymentBox.isEPCReferralComponentVisible=true;
                  this.$root.$refs.EPCReferralDetails.getEPCDetails();
                  this.amountPayable=0;
             }
             else{

                this.amountPayable=this.currentConsultationCodeObj.codeAmount;
             }
             this.$emit('getConsultationAmount', this.currentConsultationCodeObj.codeAmount,this.amountPayable)
              this.seen=false;
              this.searchConsultationCodeString='';
             // this.$router.push('/patient');
            //this.$router.push(this.$router.currentRoute);
            //
              
          },
          getConsultationCodeList: function(){
           this.$root.$refs.EnterPaymentBox.isEPCReferralComponentVisible=false;
            // alert("ConsultationCodeSearch component, searchString:" + this.searchConsultationCodeString);
            this.user = JSON.parse(localStorage.getItem('user'));
           // alert(this.user);
            let formData = new FormData();

              formData.append('searchString', this.searchConsultationCodeString);  
              var url=process.env.VUE_APP_ROOT_API+"/payment/getConsultationCodes";
              this.$http.post(url, formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                this.consultationCodeList = response.data;
                                this.seen=true;
                                //alert(this.consultationCodeList[0]);
                              
                            })
            .catch(error => {
             this.info =error.response.data;
            });
          },
          setFocus:function(){
            //alert("set focus method");
            this.$nextTick(() => this.$refs.searchConsultationCodeRef.focus())
          },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.autocomplete{font-size:.75em;color:black;}
  table{
  
  position:absolute;
  background-color:white;
  left:370px; 
  list-style-type:none;
  text-align:left;
}


</style>
