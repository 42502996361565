<template>
  <div class="generateLetterBox">
    <h3>{{ title }} </h3>
    <h5>visitId={{visitId}}</h5>
Letter Type:<select v-model="letterType" @change="isViewPdfBtnDisabled=false">    
                <option value="na">Select</option>
                <option value="EPC Initial - To Doctor">EPC Initial - To Doctor</option>
                <option value="EPC Final - To Doctor">EPC Final - To Doctor</option>
                <option value="Cert Of Attendance">Certificate Of Attendance</option>                

              </select>
              <button @click.prevent="generateLetter()">Generate</button>
              <button  @click.prevent="viewPdfDoc()" :disabled='isViewPdfBtnDisabled'>View</button>
              <br/>
            Save After Generating <input type="checkbox" v-model="saveLetter" true-value="yes" false-value="no" />{{saveLetter}}
         
             

          <div v-if="isLetterActionsVisible==true">
           <button @click.prevent="emailNow()">Email Now</button> <button @click.prevent="faxNow()">Fax Now</button> <button @click.prevent="faxInBatch('faxInbatch')">Fax In Batch</button>           
          </div>
          <br>
          <div v-if="isFaxNowBoxVisible==true">
                Fax Number:  <input type="text"  ref="faxNumberRef" v-model="faxNumber"/><button @click.prevent="sendFax()">Send</button>
          </div>
         <br/>
           <button @click="closeGenerateLetterBox">Close</button>
            <br/><br/>
            Save Letter:{{saveLetter}} | Letter Type:{{letterType}}<br>
            Server Message:{{serverMessage}}
  </div>
</template>

<script>
export default {
  name: 'GenerateLetterBox',
  props: {
    title: String,
   
    },
 data: function() {
        return {
            visitId:0,
            patientId:0,
            letterType:'na',
            saveLetter:'no',
            serverMessage:'',
            user:'',
            isLetterActionsVisible:false,
            isFaxNowBoxVisible:false,
            isViewPdfBtnDisabled:true,
                
        
        };
   },
  methods:{

          closeGenerateLetterBox:function(){
            //alert("In closGenerateLetterBox method");
            this.isLetterActionsVisible=false;
            this.isFaxNowBoxVisible=false;
            this.serverMessage='';
            this.letterType='na';
            this.$root.$refs.Appointments.showGenerateLetterBox=false;
            
          },
          faxInBatch:function(){
              this.user = JSON.parse(localStorage.getItem('user'));
              this.patientId=JSON.parse(localStorage.getItem('currentPatientId'));
              let formData = new FormData();

              formData.append('letterType', this.letterType); 
              formData.append('visitId', this.visitId); 
              formData.append('patientId', this.patientId); 
              
               var url=process.env.VUE_APP_ROOT_API+"/fax/faxInBatch";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                 //alert(response.data);
                                this.serverMessage = response.data;
                                
                                                            
                            })
            .catch(error => {
             this.serverMessage =error.response.data;
            });


          },
          faxNow:function(){

              this.isFaxNowBoxVisible=true;
              //get Doctor Fax Number
              

          },
          sendFax:function(){

              alert("In send Fax method, GenerateLetterBox component");

          },
           viewPdfDoc:function(){

             //alert("In viewPdfDoc method, GenerateLetterBox component");
               this.$root.$refs.Appointments.isViewPdfVisible=true;
               this.$root.$refs.ViewPdf.viewPdfDocFunc(this.letterType,this.visitId);

          },
          generateLetter:function(){

              this.user = JSON.parse(localStorage.getItem('user'));
              this.patientId=JSON.parse(localStorage.getItem('currentPatientId'));
              let formData = new FormData();

              formData.append('letterType', this.letterType); 
              formData.append('visitId', this.visitId); 
              formData.append('patientId', this.patientId); 
              formData.append('saveLetter', this.saveLetter); 
               var url=process.env.VUE_APP_ROOT_API+"/fax/generatePdfLetter";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                 //alert(response.data);
                                this.serverMessage = response.data;
                                this.isViewPdfBtnDisabled=false;
                                //this.isLetterActionsVisible=true;
                                                            
                            })
            .catch(error => {
             this.messageFromServer =error.response.data;
            });

              
            
          },
  },
  mounted(){
    this.$root.$refs.GenerateLetterBox = this;
    
  }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .generateLetterBox{
    position:absolute;
    left:35%;top:10%;
    z-index:5000;
    width:600px;
    height:450px;
    border-style:solid;
    border-color:gray;
    border-width:1px;
    background-color:#262525;
    opacity:0.90;
    color:white;
    
    
    }
    .textInPopup{

        margin:2%;
    }
    h3{
        margin-top:2%;
    }
</style>