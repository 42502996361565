<template>

  <div class="home">
    
    <h3>Patient Details</h3>
    
   <!--{{currentPatientObj}}<br>-->
   <DoctorSearch/>
  <form>

    <label for="salutation">Salutation</label>
    <select v-model="salutation">
                <option value="na" :selected="state=='na'">Select</option>
                <option value="Mrs">Mrs</option>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Miss">Miss</option>
                <option value="Master">Master</option>
                
      </select>
   <br>
    <label for="firstName">First Name</label>
    <input type="text"  v-model="firstName" ><span id="patientFirstNameError" class="errorClass"></span><br>
    
    <label for="lastName">Last Name</label>
    <input type="text"  v-model="lastName" ><br>
    
    <label for="phoneNumber">Phone:</label>
    <input type="text"  v-model="phoneNumber"><span id="patientPhoneNumberError" class="errorClass"></span><br>

    <label for="mobileNumber">Mobile:</label>
    <input type="text"  v-model="mobileNumber" ><span id="patientMobileNumberError" class="errorClass"></span><br>
    

    <label for="dob">DOB</label>
    <input type="text"  v-model="dob"><span id="dobError" class="errorClass"></span><br>

    <label for="email">Email:</label>
    <input type="text"  v-model="email" ><span id="patientEmailError" class="errorClass"></span><br>

    <label for="propertyName">Property Name:</label>
    <input type="text"  v-model="propertyName" ><span id="patientPropertyNameError" class="errorClass"></span><br>
   
   <label for="streetAddress">Street Address:</label>
  <input type="text"  v-model="streetAddress" ><span id="patientStreetAddressError" class="errorClass"></span><br>
   
   <label for="state">State</label>
   <select v-model="state">    
                <option value="na" :selected="salutation=='na'">Select</option>
                <option value="VIC">VIC</option>
                <option value="NSW">NSW</option>
                <option value="SA">SA</option>
                <option value="WA">WA</option>
                <option value="NT">NT</option>
                <option value="QLD">QLD</option>
                
      </select>

   <label for="postCode">Postcode:</label>
   <input type="text"  v-model="postCode" size="4"><span id="patientPostCodeError" class="errorClass"></span><br>
  
 <label for="epcCountAllocated">EPC Count Allocated:</label>
 <input type="text"  v-model="epcCountAllocated" size="4"><span id="epcCountAllocatedError" class="errorClass"></span><br>

 <label for="epcCountBalance">EPC Count Balance:</label>
 <input type="text"  v-model="epcCountBalance" size="4"><span id="epcCountBalanceError" class="errorClass"></span><br>

<label for="epcReferralDate"> Referral Date:</label>
<input type="text"  v-model="epcReferralDate" size="12"><span id="epcReferralDateError" class="errorClass"></span><br>

<label for="medicareNumber"> Medicare Num:</label>
<input type="text"  v-model="medicareNumber" size="12"><span id="medicareNumberError" class="errorClass"></span>




<label for="medicareIRNNumber"> IRN:</label>
<input type="text"  v-model="medicareIRNNumber" size="2"><span id="medicareIRNNumberError" class="errorClass" ></span><br>
DoctorId:{{doctorId}}<br>
<label for="doctorName"> Doctor Name:</label>
<input type="text"  v-model="doctorName" size="12" readonly><span id="doctorIdError" class="errorClass"></span>
<a href="#" @click.prevent="showDoctorSearch()">Select</a>
<br>



  <label for="comments">Comments:</label>
  <textarea v-model="comments" rows="4" cols="30" ></textarea><br>

  <label for="medicalHistory">Medical History:</label>
  <textarea v-model="medicalHistory" rows="4" cols="30" ></textarea><br>
  
   <label for="hearAboutUs">Hear About Us</label>
   <select v-model="hearAboutUs">    
                <option value="na" :selected="salutation=='na'">Select</option>
                <option value="doctor">Doctor</option>
                <option value="internet">Internet</option>
                <option value="alliedPractitioner">Allied Practitioner</option>
                <option value="retail">Retail (Foot Stores)</option>
                <option value="friends and famliy">Friends & Family</option>
                <option value="brochure,board,poster">Brochure / Board / Poster</option>
                <option value="yellow pages">Yellow Pages</option>
                <option value="leader newspaper">Leader Newspaper</option>

      </select><br>
    <label for="receiveAppointmentReminderSMS">Receive Appointment Reminder SMS</label>
    <input type="checkbox" v-model="receiveAppointmentReminderSMS" true-value="yes" false-value="no" /><br>

<label for="receivePromotionalInformation">Receive Information</label>
<input type="checkbox" v-model="receivePromotionalInformation" true-value="yes" false-value="no" /><br>




    <button  @click="submitFunc">Submit</button>    

    
   </form>
  </div>
</template>

<script>
// @ is an alias to /src
import DoctorSearch from '@/components/DoctorSearch.vue'
export default {
  
  components: {
  DoctorSearch
  },
   mounted: function()   {
    this.$root.$refs.PatientDetails = this;
  this.currentPatientObj=JSON.parse(localStorage.getItem('currentPatientObj'));
  this.salutation=this.currentPatientObj.salutation;
  this.firstName=this.currentPatientObj.patientFirstName;
  this.lastName=this.currentPatientObj.patientLastName;
  this.dob=this.currentPatientObj.dob;
  this.phoneNumber=this.currentPatientObj.patientPhoneNumber;
  this.mobileNumber=this.currentPatientObj.patientMobileNumber;
  this.email=this.currentPatientObj.patientEmail;
  this.propertyName=this.currentPatientObj.patientPropertyName;
  this.streetAddress=this.currentPatientObj.patientStreetAddress;
  this.state=this.currentPatientObj.patientState;
  this.postCode=this.currentPatientObj.patientPostCode;
  this.epcCountAllocated=this.currentPatientObj.epcCountAllocated;
  this.epcCountBalance=this.currentPatientObj.epcCountBalance;
  this.epcReferralDate=this.currentPatientObj.epcReferralDate;
  this.medicareNumber=this.currentPatientObj.medicareNumber;
  this.medicareIRNNumber=this.currentPatientObj.medicareIRNNumber;
  this.comments=this.currentPatientObj.comments;
  this.medicalHistory=this.currentPatientObj.medicalHistory;
  this.hearAboutUs=this.currentPatientObj.hearAboutUs;
  this.receiveAppointmentReminderSMS=this.currentPatientObj.receiveAppointmentReminderSMS;
  this.receivePromotionalInformation=this.currentPatientObj.receivePromotionalInformation;
  this.doctorId=this.currentPatientObj.doctorId;
  this.getDoctorObj(this.doctorId);
    },
  
  
  data: function() {
        return {
         
         currentPatientObj:{},
         errorObj:{},
         user:'',
         salutation:'',
         firstName:'',
         lastName:'',
         phoneNumber:'',
         mobileNumber:'',
         dob:'',
         email:'',
         propertyName:'',
         streetAddress:'',
         state:'',
         postCode:'',
         epcCountAllocated:'',
         epcCountBalance:'',
         epcReferralDate:'',
         medicareNumber:'',
         medicareIRNNumber:'',
         comments:'',
         medicalHistory:'',
         hearAboutUs:'',
         receiveAppointmentReminderSMS:'',
         receivePromotionalInformation:'',
         doctorId:'',
         doctorName:'',// not in database

         
         
        }
    },
     methods:{
             showDoctorSearch: function(){

                this.$root.$refs.SearchDoctor.shownowFunc();

             },
         submitFunc: function(){
                // clear all error messages if present
                this.clearErrorFunc();

                //user is the token from the server after logging in
                 this.user = JSON.parse(localStorage.getItem('user'));
                //alert(this.user);
                
                //alert("Iin submitFunc method,submit clicked, firstName:" + this.firstName);
                this.currentPatientObj.salutation=this.salutation;
                this.currentPatientObj.patientFirstName=this.firstName;
                this.currentPatientObj.patientLastName=this.lastName;
                this.currentPatientObj.dob=this.dob;
                this.currentPatientObj.patientEmail=this.email;
                this.currentPatientObj.patientPhoneNumber=this.phoneNumber;
                this.currentPatientObj.patientMobileNumber=this.mobileNumber;
                this.currentPatientObj.patientPropertyName=this.propertyName;
                this.currentPatientObj.patientStreetAddress=this.streetAddress;
                this.currentPatientObj.patientState=this.state;
                this.currentPatientObj.patientPostCode=this.postCode;
                this.currentPatientObj.epcCountAllocated=this.epcCountAllocated;
                this.currentPatientObj.epcCountBalance=this.epcCountBalance;
                this.currentPatientObj.epcReferralDate=this.epcReferralDate;
                this.currentPatientObj.medicareNumber=this.medicareNumber;
                this.currentPatientObj.medicareIRNNumber=this.medicareIRNNumber;
                this.currentPatientObj.comments=this.comments;
                this.currentPatientObj.medicalHistory=this.medicalHistory;
                this.currentPatientObj.hearAboutUs=this.hearAboutUs;
                this.currentPatientObj.receiveAppointmentReminderSMS=this.receiveAppointmentReminderSMS;
                this.currentPatientObj.receivePromotionalInformation=this.receivePromotionalInformation;
                this.currentPatientObj.doctorId=this.doctorId;
                var url=process.env.VUE_APP_ROOT_API+"/patient/updatePatientDetails";
             
             this.$http.post(url, this.currentPatientObj,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })

              .then(response => 
            {                
                alert(response.data);
              localStorage.setItem('currentPatientMobile', JSON.stringify(this.currentPatientObj.patientMobileNumber));
              localStorage.setItem('currentPatienId', JSON.stringify(this.currentPatientObj.id));
              localStorage.setItem('currentPatientObj', JSON.stringify(this.currentPatientObj));
                this.$router.push('/patient/');
                
             })
            .catch(error => {
               
             this.errorObj =error.response.data;
             //alert("HK"+this.errorObj);
             for(var i in this.errorObj){
                 console.log(i + "," +  this.errorObj[i]);
                 document.getElementById(i+'Error').innerHTML=this.errorObj[i]; 
                     
             }
             
            });


          },
           clearErrorFunc: function(){
                var x = document.getElementsByClassName("errorClass");
                var ierror;
                    for (ierror = 0; ierror < x.length; ierror++) {
                        x[ierror].innerHTML= "";
                   }    
          },
          getDoctorObj:function(){
            this.user = JSON.parse(localStorage.getItem('user'));
            let formData = new FormData();

              formData.append('id', this.doctorId);
               
              
            var url=process.env.VUE_APP_ROOT_API+"/doctor/getDoctorById";
             
             this.$http.post(url, formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })

              .then(response => 
            {                
               // alert("Doctor Id:"+response.data.id);
                this.doctorName=response.data.doctorFirstName + " " + response.data.doctorLastName;
                
             })
            .catch(error => {
               
             this.errorObj =error.response.data;
             alert("Error while getting doctorDetails"+this.errorObj);
             
             
            });
          }
    }
}
</script>
<style>
.errorClass{color:red;}

</style>