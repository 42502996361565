<template>
  <div class="appointmentBook">
  <Header/>{{serverMessage}} <!--windowHeight:{{windowHeight}}-->
  <AddUpdateNoteBox title="Appointment Note" v-show="showAddUpdateNoteBox"></AddUpdateNoteBox>
  <BookAppointmentBox title="Book Apppointment"  v-show="showBookAppointmentBox"></BookAppointmentBox>
    <h3>Appointment Book</h3>
   
  <table>    
      <tr>
        <td><Practitioner align="left"/></td>
          <td><Location align="left"/></td>
          <td><button @click.prevent="initialize()">Change</button></td>
      </tr>
  </table> 
  
  <table>    
      <tr>
      <td><a href="#" @click.prevent="calculateDateSelectedBackward(10,'week')"> &lt; 10 weeks </a> </td>
      <td><a href="#" @click.prevent="calculateDateSelectedBackward(1,'week')"> &lt; 1 week </a> </td>
        <td><a href="#" @click.prevent="calculateDateSelectedBackward(1,'day')"> &lt; 1 Day </a> | </td>
        
        <td><a href="#" @click.prevent="initialize()">Today</a> &nbsp;&nbsp;{{displayDate}}&nbsp;&nbsp; </td>
        <td> <DatePicker v-model="dateYYYYMMDD" valueType="format" @change="changeDateFunction()"></DatePicker></td>
         <td><a href="#" @click.prevent="calculateDateSelectedForward(1,'day')"> 1 Day > </a> | </td>
        <td><a href="#" @click.prevent="calculateDateSelectedForward(1,'week')"> 1 week > </a> </td>
        <td><a href="#" @click.prevent="calculateDateSelectedForward(10,'week')"> 10 weeks > </a> </td>
      </tr>
  </table> 
  
  
  <!--
<p align="left">Practitioner:{{practitionerName}}</p>
<p align="left">Location:{{clinicLocation}}</p>  

  <p align="left">Date To Be Sent To Server:{{dateYYYYMMDD}}</p>
   
<b>yyyy-mm-dd:{{dateYYYYMMDD}}</b> | Current Date: {{currentDate}} | Date Today:{{dateToday}}<br> 
  <b>{{dateYYYYMMDD}}</b> | {{currentDate}}<br>
{{arr}} 
{{appointmentList}}  
-->
<div class="appointmentListContainer">
<table>
  <tr>
    <td>
    <table cellpadding="">
                      
                            <tr v-for="(app,index) in appointmentList" :key="index" height="10px">
                              <td align="left">{{app.startTime}}</td>

                              <td align="left" :class="getAppointmentColor(app.appointmentType)">
                                      <div v-if="app.patientFirstName=='Available'"><a href="#"  class="modwhite" @click.prevent="bookAppointment(app.startTime,dateYYYYMMDD,clinicLocation,practitionerName)">Book Appointment</a></div>
                                      <div v-else>
                                              <span v-if="app.firstAppointment">
                                                  <a href="#" @click.prevent="checkInPatient(app.visitId,app.checkedIn)">
                                                      <span v-if="app.checkedIn=='no'" >
                                                          C 
                                                      </span>
                                                      <span v-if="app.checkedIn=='yes'" >
                                                          Y 
                                                      </span>
                                                  </a>
                                                  <a href="#" class="modwhite" @click.prevent="openPatientFile(app.id)">{{app.patientFirstName}} {{app.patientLastName}} </a>
                                                  <a href="#" @click.prevent="deleteAppointment(app.visitId)"> D</a>&nbsp;
                                                 <a href="#" @click.prevent="addUpdateNote(app.visitId,app.notes)">N</a>
                                              </span> 
                                              <!--non firstAppointment slot-->
                                              <span v-else><!--if not firstAppointment then indent 2 spaces to right to align-->
                                                  &nbsp;&nbsp;
                                                  <a href="#" class="modwhite" @click.prevent="openPatientFile(app.id)">{{app.patientFirstName}} {{app.patientLastName}} </a>
                                                
                                              </span> 

                                                <!-- <a href="#" class="modwhite" @click.prevent="openPatientFile(app.id)">{{app.patientFirstName}} {{app.patientLastName}} </a>-->
                                                 
                                                
                                              
                                              
                                               
                                       </div>  
                              </td>  


                            </tr>
                      

      </table>
      </td>
  </tr>
 </table> 
</div>
  
  </div>
</template>
<script>


// @ is an alias to /src
import Header from '@/components/Header.vue'
import Practitioner from '@/components/Practitioner.vue'
import Location from '@/components/Location.vue'
import BookAppointmentBox from '@/components/appointmentbook/BookAppointmentBox.vue'
import AddUpdateNoteBox from '@/components/appointmentbook/AddUpdateNoteBox.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  

  components: {
  Header,Practitioner,Location,BookAppointmentBox,AddUpdateNoteBox,DatePicker
  },
  mounted(){
  this.$root.$refs.AppointmentBook = this;
  this.initialize();
  
  },
  data: function() {
        return {
          currentPatientObj:{},
          patientMobileNumber:'',
          patientId:'',
          patientName:'',
          user:'',//Authorization bearer from  localStorage
          isvis:true,
          options:{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
          displayDate:'',
          displayTime:'',
          dateToday:'',
          currentDate:'',// date selected after calculation forward or back, sent to server
          dateYYYYMMDD:'',
          practitionerName:'pkaur10',// used for initial value
          clinicLocation:'Craigieburn',//used for initial value
          apptObj:{},
          appointmentList:[],
          dailyAppointmentList:[],
          showBookAppointmentBox:false,
          showAddUpdateNoteBox:false,
          checkInStatus:'no',
          serverMessage:'',
          windowHeight:window.innerWidth,

          appL:[{ "time": "08:00:00AM", "name": "Anirudh1", "surname": "Shukla1" },{ "time": "12:00:00PM", "name": "Anirudh2", "surname": "Shukla2" }],
          arr: [
              
              ],

        };
  },
  methods:{

        changeDateFunction:function(){

           // alert(this.dateYYYYMMDD.split("-").reverse().join("/"));
            //var x=this.dateYYYYMMDD.split("-").reverse().join("/");
            var changedDate=new Date(this.dateYYYYMMDD);
            this.currentDate=changedDate;
            //alert(changedDate);

            this.displayDate=changedDate.toLocaleDateString("en-US", this.options);
            this.prepareAppointmentBook();// create divs to hold appointments from server
            this.getAppointments();
            
        },

        initialize:function(){

            //alert("In initialize method of  appointmentBook component");
            //this.clinicLocation=this.$root.$refs.Location.name;
            //this.practitionerName=this.$root.$refs.Practitioner.name;
            
            this.dateToday=new Date(); 
            this.currentDate=new Date();// this is the date after calculation sent to server
            this.dateYYYYMMDD=this.currentDate.toLocaleDateString("en-US", { year: 'numeric' }) + "-" + this.currentDate.toLocaleDateString("en-US", { month: '2-digit' }) + "-" + this.currentDate.toLocaleDateString("en-US", { day: '2-digit' }) ;
            this.displayDate=this.dateToday.toLocaleDateString("en-US", this.options);
            
            this.prepareAppointmentBook();// create divs to hold appointments from server
            this.getAppointments();
            
            

        },
        calculateDateSelectedForward(amount,type){
          // amount id 1 ,2 3, type is day , week
          // this function calculates the value after moving forward i day, back 1 week etc
          
          
          var multiplier=7;

            if(type=='day'){
              this.currentDate.setDate(this.currentDate.getDate() + amount);
              this.displayDate=this.currentDate.toLocaleDateString("en-US", this.options);
            }
            if(type=='week'){
              amount=amount*multiplier;
              this.currentDate.setDate(this.currentDate.getDate() + amount);
              this.displayDate=this.currentDate.toLocaleDateString("en-US", this.options);
            }
            
            this.dateYYYYMMDD=this.currentDate.toLocaleDateString("en-US", { year: 'numeric' }) + "-" + this.currentDate.toLocaleDateString("en-US", { month: '2-digit' }) + "-" + this.currentDate.toLocaleDateString("en-US", { day: '2-digit' }) ;
            this.prepareAppointmentBook();
            this.getAppointments();
            
            
          },
           calculateDateSelectedBackward(amount,type){
          // amount id 1 ,2 3, type is day , week
          // this function calculates the value after moving forward i day, back 1 week etc
          this.arr=[];
          var multiplier=7;

            if(type=='day'){
              this.currentDate.setDate(this.currentDate.getDate() - amount);
              this.displayDate=this.currentDate.toLocaleDateString("en-US", this.options);
            }
            if(type=='week'){
              amount=amount*multiplier;
              this.currentDate.setDate(this.currentDate.getDate() - amount);
              this.displayDate=this.currentDate.toLocaleDateString("en-US", this.options);
            }
        
            this.dateYYYYMMDD=this.currentDate.toLocaleDateString("en-US", { year: 'numeric' }) + "-" + this.currentDate.toLocaleDateString("en-US", { month: '2-digit' }) + "-" + this.currentDate.toLocaleDateString("en-US", { day: '2-digit' }) ;
            this.prepareAppointmentBook();
            this.getAppointments();
             
          },
          bookAppointment:function(time,dateYYYYMMDD,clinicLocation,practitionerName){
            
            //alert("BookAppointmentBox:" + time + ","  + dateYYYYMMDD + "," + clinicLocation + "," + practitionerName);
            this.$root.$refs.BookAppointmentBox.initialize(time,dateYYYYMMDD,clinicLocation,practitionerName);

            this.showBookAppointmentBox=true;
           this.$root.$refs.PatientSearchStandalone.setFocus();
        
            },

           
          getAppointments(){
              
              this.appointmentList='';
              
             this.user = JSON.parse(localStorage.getItem('user'));
            
              let formData = new FormData();

              formData.append('date', this.dateYYYYMMDD); 
              formData.append('clinicLocation', this.clinicLocation); 
              formData.append('practitionerName', this.practitionerName); 
              var url=process.env.VUE_APP_ROOT_API+"/appointment/getAppointmentsByDateAndLocationAndPractitioner";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                this.appointmentList = response.data;
                               // alert(response.data);
                              
                            })
            .catch(error => {
             this.messageFromServer =error.response.data;
            });

          },
          prepareAppointmentBook:function(){
                this.arr=[];
                var count=0;
                var i=8;
                var j=0;
                var iString="00";
                var jString="00";
                var time=i +"-" + j;
               // var ampm="AM";
                
                while(count<=11){

                    
                    if( i<10){
                      iString="0" + i;
                    }else{
                      iString=i;
                    }

                    while(j<60){
                      
                      if(j==0){
                        jString="0" +0;
                      }
                      else{
                        jString=j;
                      }
                          //time=iString + ":" + jString + ":00" +  ampm;
                          time=iString + ":" + jString + ":00";
                      this.arr.push({ time: time,name: 'Available',dateSelected: this.dateYYYYMMDD });
                      j=j+20;
                      
                    }
                    //if(i==12){i=0;}
                    /**
                    if(i>10){ampm="PM";}
                    else{
                      ampm="AM";
                    }*/
                    j=0;
                    
                    
                    i++;
                    
                    count++;
                }
               // alert(this.$refs.appt[1]);
                //alert(this.$refs.appt1.innerHTML);
                //alert(this.$refs['9:0'][0].innerHTML);
                //this.$refs['app-12-0-2022-09-26'][0].innerHTML="<a href='#'>HKR</a>";
          },
          checkInPatient:function(visitId,checkInStatus){
                 // alert("In checkInPatient, visitId:" + visitId + ",checkInStatus:" + checkInStatus)
                  this.user = JSON.parse(localStorage.getItem('user'));
                  let formData = new FormData();

                  formData.append('visitId', visitId);  
                  formData.append('checkInStatus',checkInStatus);  
                  var url=process.env.VUE_APP_ROOT_API+"/appointment/checkInPatient";
                  this.$http.post(url, formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
                  .then(response => {
                                this.serverMessage=response.data;
                                this.getAppointments();

                            })
            .catch(error => {
             this.info =error.response.data;
            });
          },
          openPatientFile:function(id){
                  //alert("In get patientById method, id:" + id )
                  this.user = JSON.parse(localStorage.getItem('user'));
                  let formData = new FormData();

                  formData.append('id', id);  
                  var url=process.env.VUE_APP_ROOT_API+"/patient/getByPatientId";
                  this.$http.post(url, formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
                  .then(response => {
                                this.currentPatientObj = response.data;
                                this.patientMobileNumber=this.currentPatientObj.patientMobileNumber;
                                this.patientId=this.currentPatientObj.id;
                                this.patientName=this.currentPatientObj.patientFirstName  + " " + this.currentPatientObj.patientLastName
                                //alert("selected patient from appointment book:"+this.patientMobileNumber + ",id:" + this.patientId);
                                localStorage.setItem('currentPatientMobile', this.patientMobileNumber);
                                localStorage.setItem('currentPatientId', this.patientId);
                                localStorage.setItem('currentPatientName', this.patientName);
                                localStorage.setItem('currentPatientObj',JSON.stringify(this.currentPatientObj));
                                //this.$router.push('/patient');
                                this.$router.push('/patient/patientsummary');
                            })
            .catch(error => {
             this.info =error.response.data;
            });
          },
          getAppointmentColor:function(appointmentType){


                if(appointmentType=="Standard Consultation")
                  {return "modblue";}
                else if(appointmentType=="New Patient"){
                   return "mintgreen";
                }
                else {

                   return "modgray";
                }

          },
          deleteAppointment(visitId){
              
             this.user = JSON.parse(localStorage.getItem('user'));
            
              let formData = new FormData();

              formData.append('visitId', visitId); 
              
              var url=process.env.VUE_APP_ROOT_API+"/appointment/deleteAppointment";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                this.serverMessage= response.data;
                                 this.getAppointments();
                               // alert(response.data);
                              
                            })
            .catch(error => {
             this.serverMessage=error.response.data;
            });
           
        },
         addUpdateNote(visitId,note){
        
         //call method in AddUpdateNote component     
             //alert("In addUpdateNote method in appointmentBook" + note);
             this.showAddUpdateNoteBox=true;
             this.$root.$refs.AddUpdateNoteBox.setFocus();
             this.$root.$refs.AddUpdateNoteBox.getNote(visitId,note);
        }

  }


}


</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.appointmentListContainer{
    overflow: auto;
    width:1000px;
    height:1000px;
    
    
    
    }
.crockgreen{
  background-color:#60783b;
  color: white;
}
.peargreen{

  background-color:#74B72E;
  color: white;
  font-size:12px;
  padding:6px;

}
.mintgreen{
background-color:#3db489;
color: #faebd7;
font-size:12px; 
border-bottom: 1px solid white;
padding-left:6px;
 padding-right:6px;
}
.modblue{
 background-color: #1ea1ff;
 color:white;
 font-size:12px;
  border-bottom: 1px solid white;
 padding-left:6px;
 padding-right:6px;
}
.modgray{
 background-color: #7e7e7e;
 color:white;
 font-size:12px;
 border-bottom: 1px solid white;
 padding-left:6px;
 padding-right:6px;
}
a.modwhite{
  color:white;
  text-decoration:none;
}



</style>

