<template>

  <div class="home">
    <Header></Header>
    <h1>Doctor</h1>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'

export default {
  
  components: {
  Header
  }
}


</script>
