<template>
  <div class="smsLogs">
    <h1>{{ title }}</h1>
    <form>
       
       <input type="submit" value="View Logs" @click="getSMSLogs"/>
    </form>
   
  </div>
</template>

<script>
export default {
  name: 'SMSLogs',
  props: {
    title: String
  },
  
 data: function() {
        return {
          credentials:{},
          smsLogs:[],
          user:''
        
        
        };
   },
  methods:{

          getSMSLogs: function(e){
              e.preventDefault();
              
              this.user = JSON.parse(localStorage.getItem('user'));
              this.credentials.mobile=JSON.parse(localStorage.getItem('currentPatientMobile'));
              alert("Current Patient Mobile:"+JSON.parse(localStorage.getItem('currentPatientMobile')));
              var url=process.env.VUE_APP_ROOT_API+"/correspondence/getSMSLogs";
             
             this.$http.post(url, {
                        mobile:JSON.parse(localStorage.getItem('currentPatientMobile'))
             },
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                this.smsLogs = response.data;
                               // alert(this.smsLogs);
                                this.$emit('getSMSLogs',this.smsLogs);
                            })
                                      
            .catch(error => {
             this.messageStatus =error.response.data;
            });
          }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
