<template>
  <div class="sendEmail">
   <center>
   <br>
    <h5>{{ title }}</h5>
    <p style='margin-left:10px'>
        <input type="text"  v-model="emailId" ref="emailIdRef" placeholder="Enter Email"> &nbsp;
        <button  @click.prevent="sendEmail">Send</button>  <button  @click.prevent="closeSendEmail">Close</button>
    </p>
  <!-- Letter Type:{{letterType}} | VisitId:{{visitId}} | Patient Id:{{patientId}}-->
  {{serverMessage}}
   </center>
  </div>
</template>

<script>

export default {
  name: 'SendEmail',
  mounted(){
    this.$root.$refs.SendEmail = this;
    
  },
  props: {
    title: String,
    letterType: String,
    visitId:Number,
    patientId:Number,

  },
 data: function() {
        return {
         
         
         emailId:'',
         serverMessage:'',
         user:''
            
        };
   },
  methods:{
         setFocus:function(){
                this.$nextTick(() => this.$refs.emailIdRef.focus());
                this.getPatientEmail();
         },
         closeSendEmail: function(){
            //alert("In closeSendEFax method of SendEfax component");
            this.serverMessage="";
            this.$root.$refs.ViewPdf.isSendEmailBoxVisible=false;
         },

         getPatientEmail:function(){
              this.user = JSON.parse(localStorage.getItem('user'));
              this.patientId=JSON.parse(localStorage.getItem('currentPatientId'));
              let formData = new FormData();
              formData.append('patientId', this.patientId); 
              var url=process.env.VUE_APP_ROOT_API+"/patient/getPatientEmailById";
             
              this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                // alert(response.data);
                                this.emailId = response.data;
                                
                                                            
                            })
            .catch(error => {
             this.serverMessage =error.response.data;
            });

         },
            sendEmail: function(){
            //alert("In sendEmail method of SendEmail component");
            this.user = JSON.parse(localStorage.getItem('user'));
            this.patientId=JSON.parse(localStorage.getItem('currentPatientId'));
            let formData = new FormData();

              
            formData.append('letterType', this.letterType); 
            formData.append('visitId', this.visitId); 
            formData.append('patientId', this.patientId); 
            formData.append('emailId', this.emailId); 
              
            var url=process.env.VUE_APP_ROOT_API+"/fax/sendEmailWithAttachment";

            this.$http.post(url,formData ,

                                    {
                                        headers: {
                                                'Authorization': 'Bearer ' + this.user
                                                }
                                    })
            .then(response => {
                            // alert(response.data);
                            this.serverMessage = response.data;
                            
                                                        
                        })
            .catch(error => {
            this.serverMessage =error.response.data;
            });

         },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sendEmail{

        color:white;
        position:absolute;
        border-style:solid;
        border-color:gray;
        border-width:1px;
        background-color:black;
        width:85%;height:22%;
        left:10%;top:10%;
        z-index:9000;
}
</style>
