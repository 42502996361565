<template>
  <div class="header">
    <router-link to="/Home">Home</router-link> |
    <router-link to="/AppointmentBook">Appointment Book</router-link> |
    <router-link to="/AppointmentReminder">AppointmentReminder</router-link> |
     <router-link to="/Patient/PatientSummary">Patient</router-link> |
     <router-link to="/Doctor">Doctor</router-link> |
     <router-link to="/CallDetails">Call Details</router-link> |
     <router-link to="/Logout">Logout</router-link>
  </div>
</template>

<script>
export default {
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
