import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import AppointmentReminder from '../views/AppointmentReminder.vue'
import Home from '../views/Home.vue'
import Patient from '../views/Patient.vue'
import Doctor from '../views/Doctor.vue'
import PatientDetails from '../views/patient/PatientDetails.vue'
import Appointments from '../views/patient/Appointments.vue'
import PatientCorrespondence from '../views/patient/PatientCorrespondence.vue'
import PatientSummary from '../views/patient/PatientSummary.vue'
import SMSLogs from '../views/patient/PatientSMSLogs.vue'
import CallDetails from '../views/CallDetails.vue'
import AppointmentBook from '../views/appointmentbook/AppointmentBook.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/appointmentBook',
    name: 'AppointmentBook',
    component: AppointmentBook
  },
  {
    path: '/appointmentReminder',
    name: 'AppointmentReminder',
    component: AppointmentReminder
  },
  {
    path: '/home',
    name: 'Home',
    component:Home
  },
  {
    path: '/doctor',
    name: 'Doctor',
    component: Doctor,
    
  },
  {
    path: '/patient',
    name: 'Patient',
    component:Patient,
      //Child Routes
      children: [
        {
          path: 'patientsummary',
          name: 'PatientSummary',
          component: PatientSummary
        },
        {
          path: 'patientdetails',
          name: 'PatientDetails',
          component: PatientDetails
        },
        {
          path: 'appointments',
          name: 'Appointments',
          component: Appointments,
          
        },
        
        {
          path: 'correspondence',
          name: 'PatientCorrespondence',
          component: PatientCorrespondence,
          children:[
            {
              path:'smslogs',
              name:'SMSLogs',
              component:SMSLogs
            }
          ]
        }
        
      ]
  },
  {
    path: '/callDetails',
    name: 'CallDetails',
    component: CallDetails
    
  },
  {
    path: '/logout',
    name: 'Logout',
    component:Login
  }
]

const router = new VueRouter({
  routes
})

export default router
