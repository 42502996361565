<template>
  <div class="patientSearch">
   
    <form>
        <label for="searchPatientString">Search:</label>
        <input type="text" name="searchPatientString" ref="searchPatientString" v-model="searchPatientString" @keyup="getPatientList()"/>
          <ul v-show="seen">
         <li v-for="(item,index) in patientDetails" :key="index"><a href="#" class="autocomplete" @click.prevent="patientSelectedFunc(item.id,index)">{{ item.patientFirstName}} {{ item.patientLastName}} DOB:{{item.dob}}</a></li>
         </ul>
    </form>

    
  
  </div>
</template>

<script>
export default {
  name: 'SearchPatient',
  props: {
    title: String
  },
 data: function() {
        return {
          patientDetails:[],
          currentPatientId:0,
          currentPatientObj:{},
          currentPatientName:'',
          searchPatientString:'',
          user:'',
          seen:false
        };
   },
  methods:{
          patientSelectedFunc:function(id,index){

              //alert("Address Selected" +item);
               this.currentPatientId=id;
              this.currentPatientName=this.patientDetails[index].patientFirstName + " " + this.patientDetails[index].patientLastName;
              this.$parent.currentPatientName=this.currentPatientName;
              this.$parent.currentPatientMobile=this.patientDetails[index].patientMobileNumber;
              this.$parent.currentPatientPhone=this.patientDetails[index].patientPhoneNumber;

              this.currentPatientObj=this.patientDetails[index];

              localStorage.setItem('currentPatientMobile', JSON.stringify(this.patientDetails[index].patientMobileNumber));
              localStorage.setItem('currentPatientId', JSON.stringify(this.patientDetails[index].id));
              localStorage.setItem('currentPatientObj', JSON.stringify(this.currentPatientObj));
              this.seen=false;
              this.searchPatientString='';
             this.$root.$refs.PatientSummaryC.getPatientSummary();
              this.$router.push('/patient/patientSummary');
            //this.$router.push(this.$router.currentRoute);
              
          },
          getPatientList: function(){
             // alert(this.searchPatientString);
             this.user = JSON.parse(localStorage.getItem('user'));
            let formData = new FormData();

                formData.append('searchStr', this.searchPatientString);  
              //var url=process.env.VUE_APP_ROOT_API+"/patient/getByName";
              var url=process.env.VUE_APP_ROOT_API+"/patient/getBySearchString";
              this.$http.post(url, formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                this.patientDetails = response.data;
                                this.seen=true;
                              //  alert(response.data);
                              
                            })
            .catch(error => {
             this.info =error.response.data;
            });
          }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.autocomplete{font-size:.75em;color:gray;}
ul{list-style-type:none;}
</style>
