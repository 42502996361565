<template>

  <div class="doctorSearch" v-show="shownow">
  
   <br/>
    <form>
        <label for="searchDoctorString">Search Doctor:</label>
        <input type="text" name="searchDoctorString" ref="searchDoctorStringRef" v-model="searchDoctorString" @keyup="getDoctorList()"/>
          <ul v-show="seen">
         <li v-for="(item,index) in doctorDetails" :key="index"><a href="#" class="autocomplete" @click.prevent="doctorSelectedFunc(item.id,item.doctorFirstName,item.doctorLastName)">{{ item.doctorFirstName}} {{ item.doctorLastName}} Location:{{item.doctorSuburb}}</a></li>
         </ul>
    </form>
    
    <br>
    
    <button @click="shownow=false;initialize()" >Close</button>
  
  </div>
</template>

<script>

export default {
  name: 'SearchDoctor',
  created:function(){
  
  },
  mounted(){

      this.$root.$refs.SearchDoctor = this;
   
  },
  components:{
    

  },
  props: {
    title: String
  },
 data: function() {
        return {
          doctorDetails:[],
          doctorId:0,
          dococtorName:'',
          doctorEmail:'',
          doctorFaxNumber:'',
          messageFromServer:'',
          shownow:false,
          seen:false,
          doctorDetailsShow:false,
          searchDoctorString:'',
          doctorComments:'',
          emailUid:'',
          emailSubject:''
          
        };
   },
  methods:{
          shownowFunc:function(){
             
            this.shownow=true;
            this.$nextTick(() => this.$refs.searchDoctorStringRef.focus())
          },
          initialize:function(){

          this.doctorDetails=[];  
          this.doctorId=0;
          this.doctorName='';
          this.shownow=false;
          this.seen=false;
          this.doctorDetailsShow=false;
          this.searchDoctorString='';
          

          },
          doctorSelectedFunc:function(id,doctorFirstName,doctorLastName){

            alert("Patient Selected(Id):" +id);
            
            this.doctorId=id;
            this.seen=false;
            this.searchDoctorString='';   
            this.$root.$refs.PatientDetails.doctorId = this.doctorId;         
            this.$root.$refs.PatientDetails.doctorName = doctorFirstName + " " + doctorLastName;         
           
          },
           getDoctorList: function(){
             //alert(this.searchDoctorString);
             this.user = JSON.parse(localStorage.getItem('user'));
             this.doctorDetailsShow=false;
              let formData = new FormData();

              formData.append('searchStr', this.searchDoctorString);  
              var url=process.env.VUE_APP_ROOT_API+"/doctor/getDoctorByName";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                this.doctorDetails = response.data;
                               // alert(this.doctorDetails);
                                this.seen=true;
                                
                              //alert(response.data);
                              
                            })
            .catch(error => {
             this.messageFromServer =error.response.data;
            });
          },
         
 

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.doctorSearch{
  position:absolute;
  left:35%;top:20%;
  z-index:5000;
  width:400px;
  height:150px;
  border-style:solid;
  border-color:gray;
  border-width:1px;
  background-color:#262525;
  opacity:0.95;
  color:white;
  }
  .doctorDetails{
  text-align:left;
  font-size:.9em;
  color:white;
  margin:10px;
  
  }
  .messageFromServer{
    color:white;
  }
.autocomplete{font-size:.75em;color:white;}
ul{list-style-type:none;
margin-left:25%;
  z-index:7000;
  text-align:left;
}

</style>
