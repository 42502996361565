<template>
  <div class="productSearch">
   
    <form>
        <label for="searchProductString">Enter Product:</label>
        <input type="text" name="searchProductString" ref="searchProductStringRef" v-model="searchProductString" @keyup="getProductList()"/>
        Qty: <input type="text"  ref="productQtyRef" v-model="productQty" size="3"/>
        <button @click.prevent="addProductToCart()" :disabled="isAddProductBtnDisabled">Add</button>
        <table v-show="seen" class="searchDropdown">
         
         <tr v-for="(item,index) in productList" :key="index">&nbsp;&nbsp;<a href="#" class="autocomplete" @click.prevent="productSelectedFunc(item.id,index)">({{ item.productCode}}) - {{item.productName}}</a>&nbsp;&nbsp;</tr>
         
         </table>

    </form>

<!--  Product:({{currentProductObj.productCode}}){{currentProductObj.productName}}  Amount:${{currentProductObj.priceBeforeDiscount}}<br>-->
Cart:<center>
         <table cellpadding="5">
            <tr><th>Qty</th><th>Product</th><th>Unit Price</th><th>Total</th><th>Remove</th></tr>
            
            <tr v-for="(item,index) in productCart" :key="index">
            
            <td> {{item.qty}} </td>
            <td>({{ item.productCode}}) - {{item.productName}}</td>
            <td>{{item.unitPrice}}</td>
            <td>{{item.total}}</td> 
            <td><a href="#" @click.prevent="removeItemFromProductCart(index)">X</a></td>
            </tr>
            
         </table>
</center>
        PRODUCT TOTAL:${{productCartTotal}}
  </div>
</template>

<script>

export default {
  name: 'ProductSearch',
  props: {
    title: String
  },
  created(){
    
  },

   mounted(){

      this.$root.$refs.ProductSearch = this;
      
      
  },
  computed:{
    
   
  },
 data: function() {
        return {
          productList:[],
          productCart:[],
          productCartTotal:0,
          currentProductObj:{},
          productQty:1,
          searchProductString:'',
          user:'',
          seen:false,
          amountPayable:0,
          serverMessage:'',
          isAddProductBtnDisabled:false,
        };
   },
  methods:{
          productSelectedFunc:function(id,index){

             // alert("Product Selected" +id);
             this.currentProductObj=this.productList[index];
             
             //this.$emit('getConsultationAmount', this.currentConsultationCodeObj.codeAmount,this.amountPayable)
              this.seen=false;
              this.searchProductString=this.currentProductObj.productCode + "," +this.currentProductObj.productName;
             //this.$router.push('/patient');
            //this.$router.push(this.$router.currentRoute);
        
              
          },
          getProductList: function(){
           
            // alert("ConsultationCodeSearch component, searchString:" + this.searchConsultationCodeString);
            this.user = JSON.parse(localStorage.getItem('user'));
           
            let formData = new FormData();

              formData.append('searchString', this.searchProductString);  
              var url=process.env.VUE_APP_ROOT_API+"/product/getProductByName";
              this.$http.post(url, formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                               // alert(response.data);
                                this.productList = response.data;
                                this.seen=true;
                                
                              
                            })
            .catch(error => {
             this.serverMessage =error.response.data;
            });
          },
          setFocus:function(){
            //alert("set focus method");
            this.$nextTick(() => this.$refs.searchProductStringRef.focus())
          },
          addProductToCart:function(){
            //alert("set focus method");
            const cartItem={};
            cartItem.id=this.currentProductObj.id;
            cartItem.categoryId=this.currentProductObj.categoryId;
            cartItem.qty=this.productQty;
            cartItem.productCode=this.currentProductObj.productCode;
            cartItem.productName=this.currentProductObj.productName;
            cartItem.unitPrice=this.currentProductObj.priceBeforeDiscount;
            cartItem.total=(cartItem.qty)*(cartItem.unitPrice);
           this.productCart.push(cartItem);
           this.searchProductString='';
           this.getProductCartTotal();
          },
         getProductCartTotal:function() {
               this.productCartTotal=0;
                for (let i = 0; i < this.productCart.length; i++){

                        this.productCartTotal+=this.productCart[i].total;
                }
               this.$emit('getProductTotal', this.productCartTotal,this.productCart)
     
    },

        removeItemFromProductCart:function(index) {
               this.productCart.splice(index,1);
               this.getProductCartTotal();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.autocomplete{font-size:.75em;color:black;}

.searchDropdown{
  
  position:absolute;
  color:black;
  background-color:white;
  left:270px; 
  list-style-type:none;
  text-align:left;
  
}

</style>
