import { render, staticRenderFns } from "./BookAppointmentBox.vue?vue&type=template&id=369544e4&scoped=true&"
import script from "./BookAppointmentBox.vue?vue&type=script&lang=js&"
export * from "./BookAppointmentBox.vue?vue&type=script&lang=js&"
import style0 from "./BookAppointmentBox.vue?vue&type=style&index=0&id=369544e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "369544e4",
  null
  
)

export default component.exports