<template>
  <div class="sendEFax">
   <center>
   <br>
    <h5>{{ title }}</h5>
    <p style='margin-left:10px'>
        <input type="text"  v-model="eFaxNumber" placeholder="Enter Fax Number 61"> &nbsp;
        <button  @click.prevent="sendEFax">Send</button>  <button  @click.prevent="closeSendEFax">Close</button>
    </p>
  <!-- Letter Type:{{letterType}} | VisitId:{{visitId}} | Patient Id:{{patientId}}-->
  {{serverMessage}}
   </center>
  </div>
</template>

<script>

export default {
  name: 'SendEFax',
  mounted(){
    this.$root.$refs.SendEFax = this;
    
  },
  props: {
    title: String,
    letterType: String,
    visitId:Number,
    patientId:Number,

  },
 data: function() {
        return {
         
         
         eFaxNumber:'',
         serverMessage:'',
         user:''
            
        };
   },
  methods:{
         
         closeSendEFax: function(){
            //alert("In closeSendEFax method of SendEfax component");
            this.serverMessage="";
            this.$root.$refs.ViewPdf.isSendEFaxBoxVisible=false;
         },
            getDoctorFaxNumber: function(){
            //alert("In getDoctorFaxNumber method of SendEfax component, patientId:"+ this.patientId);
             this.user = JSON.parse(localStorage.getItem('user'));
              this.patientId=JSON.parse(localStorage.getItem('currentPatientId'));
              let formData = new FormData();

              
              formData.append('patientId', this.patientId); 
              
               var url=process.env.VUE_APP_ROOT_API+"/doctor/getDoctorFaxByPatientId";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                // alert(response.data);
                                this.eFaxNumber = response.data;
                                
                                                            
                            })
            .catch(error => {
             this.serverMessage =error.response.data;
            });

            
         },
          sendEFax: function(){
            //alert("In sendEFax method of SendEfax component");
            this.user = JSON.parse(localStorage.getItem('user'));
              this.patientId=JSON.parse(localStorage.getItem('currentPatientId'));
              let formData = new FormData();

              
              formData.append('letterType', this.letterType); 
                formData.append('visitId', this.visitId); 
                  formData.append('patientId', this.patientId); 
                    formData.append('faxNumber', this.eFaxNumber); 
              
               var url=process.env.VUE_APP_ROOT_API+"/fax/sendFaxByEmail";
             
               this.$http.post(url,formData ,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
              .then(response => {
                                // alert(response.data);
                                this.serverMessage = response.data;
                                
                                                            
                            })
            .catch(error => {
             this.serverMessage =error.response.data;
            });

         },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sendEFax{

        color:white;
        position:absolute;
        border-style:solid;
        border-color:gray;
        border-width:1px;
        background-color:black;
        width:85%;height:22%;
        left:10%;top:10%;
        z-index:9000;
}
</style>
