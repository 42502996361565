<template>
  <div class="healthFundSearch">
   
    <form>
        <label for="healthFundSearchString">Health Fund:</label>
        <input type="text" name="healthFundSearchString" ref="healthFundSearchStringRef" v-model="healthFundSearchString" @keyup="getHealthFundList()" @focus="healthFundSearchString=''"/>
        <table v-show="seen" >
         
         <tr v-for="(item,index) in healthFundList" :key="index">&nbsp;&nbsp;<a href="#" class="autocomplete" @click.prevent="healthFundSelectedFunc(item.id,index)"> {{item.fundName}}</a>&nbsp;&nbsp;</tr>
         
         </table>
    </form>

  
    
  </div>
</template>

<script>

export default {
  name: 'HealthFundSearch',
  props: {
    title: String
  },
  created(){
    
  },

   mounted(){

      this.$root.$refs.HealthFundSearch = this;
      
      
  },
 data: function() {
        return {
          healthFundList:[],
          currentHealthFundObj:{},
          healthFundSearchString:'',
          user:'',
          seen:false,
          
        };
   },
  methods:{
          healthFundSelectedFunc:function(id,index){

              //alert("Address Selected" +item);
            this.currentHealthFundObj=this.healthFundList[index];
            
              this.seen=false;
              this.healthFundSearchString=this.currentHealthFundObj.fundName;
             // this.$router.push('/patient');
            //this.$router.push(this.$router.currentRoute);
            //
              
          },
          getHealthFundList: function(){
           
            // alert("ConsultationCodeSearch component, searchString:" + this.searchConsultationCodeString);
            this.user = JSON.parse(localStorage.getItem('user'));
           // alert(this.user);
            let formData = new FormData();

              formData.append('searchString', this.healthFundSearchString);  
              var url=process.env.VUE_APP_ROOT_API+"/healthfund/getHealthFundList";
              this.$http.post(url, formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                this.healthFundList = response.data;
                                this.seen=true;
                                //alert(this.consultationCodeList[0]);
                              
                            })
            .catch(error => {
             this.serverMessage =error.response.data;
            });
          },
          setFocus:function(){
            //alert("set focus method");
            this.$nextTick(() => this.$refs.searchHealthFundSearchStringRef.focus())
          },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.autocomplete{font-size:.75em;color:black;}
  table{
  
  position:absolute;
  background-color:white;
  left:370px; 
  list-style-type:none;
  text-align:left;
}


</style>

