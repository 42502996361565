<template>
  <div class="patientSearchStandalone">
   
    <form>
        <label for="searchPatientString">Patient:</label>
        <input type="text" name="searchPatientString" ref="searchPatientStringRef" v-model="searchPatientString" @keyup="getPatientList()"/>
         
      <ul v-show="seen">
         <li v-for="(item,index) in patientDetails" :key="index"><a href="#" class="autocomplete" @click.prevent="patientSelectedFunc(item.id,index)">{{ item.patientFirstName}} {{ item.patientLastName}} DOB:{{item.dob}}</a></li>
      </ul>

    </form>

    
  
  </div>
</template>

<script>
export default {
  name: 'SearchPatient',
  props: {
    title: String
  },mounted(){
  this.$root.$refs.PatientSearchStandalone = this;
 
  },
 data: function() {
        return {
          patientDetails:[],
          currentPatientId:0,
          currentPatientObj:{},
          currentPatientName:'',
          searchPatientString:'',
          user:'',
          seen:false
        };
   },
  methods:{ setFocus:function(){
            
            this.$nextTick(() => this.$refs.searchPatientStringRef.focus())
        },
          patientSelectedFunc:function(id,index){

             // alert("Patient Selected" +id);
              this.currentPatientId=id;
              this.$parent.patientId=id;
              this.currentPatientName=this.patientDetails[index].patientFirstName + " " + this.patientDetails[index].patientLastName;
              this.$parent.currentPatientName=this.currentPatientName;
              this.$parent.currentPatientMobile=this.patientDetails[index].patientMobileNumber;
              this.$parent.currentPatientPhone=this.patientDetails[index].patientPhoneNumber;

              this.currentPatientObj=this.patientDetails[index];
              this.seen=false;
              //this.searchPatientString='';
              this.searchPatientString=this.patientDetails[index].patientFirstName + " " + this.patientDetails[index].patientLastName;
              
          },
          getPatientList: function(){
             // alert(this.searchPatientString);
             this.user = JSON.parse(localStorage.getItem('user'));
             let formData = new FormData();

              formData.append('searchStr', this.searchPatientString);  
              var url=process.env.VUE_APP_ROOT_API+"/patient/getByName";
              this.$http.post(url, formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                this.patientDetails = response.data;
                                this.seen=true;
                              // alert(response.data);
                              
                            })
            .catch(error => {
             this.info =error.response.data;
            });
          }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.autocomplete{
  font-size:.75em;color:white;
  z-index:1000;
}
ul{
  list-style-type:none;
  position:absolute;
  margin-left:4%;
  text-align:left;
  }
</style>
