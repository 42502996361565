<template>
  <div class="hello">
    <h1>{{ title }}</h1>
    <form>
        <label for="emailId">Email:</label><br>
        <input type="text"  v-model="username"><br>
        <label for="passwd">Password:</label><br>
        <input type="password"  v-model="password"><br>
        <input type="submit" value="Submit" @click="submitFunc"/>
    </form>
    {{info}}
  </div>
</template>

<script>
export default {
  name: 'LoginModule',
  props: {
    title: String
  },
  mounted:function(){


   // alert("Mounted Called In Login Component");
    localStorage.setItem('user', JSON.stringify(''));
    localStorage.setItem('currentPatientId', JSON.stringify(''));
    localStorage.setItem('currentPatientObj', JSON.stringify(''));
     localStorage.setItem('currentPatientMobile', JSON.stringify(''));
  },
 data: function() {
        return {
          credentials:{},
          username: '',
          password:'',
          info:''
        
        };
   },
  methods:{

          submitFunc: function(e){
              e.preventDefault();
              var self=this;
              self.credentials.username=self.username;
              self.credentials.password=self.password;
              var url=process.env.VUE_APP_ROOT_API+"/api/auth/signin";
             this.$http.post(url,self.credentials)
            .then(response => {
                                this.info = response.data.accessToken;
                                localStorage.setItem('user', JSON.stringify(response.data.accessToken));
                                 let user = JSON.parse(localStorage.getItem('user'));
                                 alert(user);
                                this.$router.push({ name: 'Home' })
                            })
            .catch(error => {
             this.info =error.response.data;
            });
          }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
