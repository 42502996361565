<template>
  <div class="patientSummaryC">
 
   <p align="left">
        <b> First Booked :</b>{{patientSummaryObj.firstAppointmentDate}}<br/>
        <b> Next Appointment:</b>{{patientSummaryObj.nextAppointmentDate}}<br/>
        <b> Previous Appointment:</b>{{patientSummaryObj.previousAppointmentDate}}({{patientSummaryObj.previousAppointmentLocation}})<br/>
        <b>Last PaymentType:</b>{{patientSummaryObj.lastPaymentType}}<br/>

        <b> Doctor:</b>{{doctorName}}
        <br/>
        <b>Comments:</b><br/>
        <textarea v-model="patientSummaryObj.comments" rows="4" cols="30" ></textarea><br>
       <!-- {{patientSummaryObj.comments}}<br/>-->

   </p>
   
 <!--{{patientSummaryObj}}-->
    
  
  </div>
</template>

<script>
export default {
  name: 'PatientSummaryC',
  props: {
    title: String
  },
  mounted: function()   {

      this.$root.$refs.PatientSummaryC = this;
      this.getPatientSummary();
  },
 data: function() {
        return {
          patientSummaryObj:{},
          doctorName:'',
          comments:'',
          currentPatientId:0,
          user:'',
         
        };
   },
  methods:{
          
          getPatientSummary: function(){
             
             this.user = JSON.parse(localStorage.getItem('user'));
             this.currentPatientId= JSON.parse(localStorage.getItem('currentPatientId'));
            
            //alert("Current Patient Id :" + this.currentPatientId);
             let formData = new FormData();

             formData.append('patientId', this.currentPatientId);  
              
              var url=process.env.VUE_APP_ROOT_API+"/patient/getPatientSummaryById";
              this.$http.post(url, formData,
                
                                        {
                                         headers: {
                                                  'Authorization': 'Bearer ' + this.user
                                                  }
                                        })
            .then(response => {
                                this.patientSummaryObj = response.data;
                               // alert(this.patientSummaryObj.doctor);
                               this.doctor=this.patientSummaryObj.doctor;
                                if(this.doctor==null)
                                {
                                  //alert("No Doctor");
                                  this.doctorName="NA"
                                }else{

                                  this.doctorName=this.doctor.doctorFirstName + " " + this.doctor.doctorLastName + "(" + this.doctor.doctorSuburb + ")";
                                }
                                
                              
                              
                            })
            .catch(error => {
             this.info =error.response.data;
            });
          }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.autocomplete{font-size:.75em;color:gray;}
ul{list-style-type:none;}
</style>
